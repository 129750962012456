import store from '@/store';
import moment from 'moment';
import {locationsList} from "@/constants/locationsList";

export function humanizeDate(date) {
    
    const month = {
        '01': 'months.ene',
        '02': 'months.feb',
        '03': 'months.mar',
        '04': 'months.abr',
        '05': 'months.mai',
        '06': 'months.jun',
        '07': 'months.jul',
        '08': 'months.ago',
        '09': 'months.sep',
        '10': 'months.oct',
        '11': 'months.nov',
        '12': 'months.dec',
    }
    
    const splitDate = date.split('-');

    const humanized = [
        splitDate[2],
        month[splitDate[1]]
    ];
    
    return humanized;
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


// First a couple helper functions
function $(id) {
    return !id || id.nodeType === 1 ? id : document.getElementById(id);
}
function isType(o, t) { return (typeof o).indexOf(t.charAt(0).toLowerCase()) === 0; }

// Here's the meat and potatoes
export async function checkImage(src, cfg) {
    var img, prop, target;
    cfg = cfg || (isType(src, 'o') ? src : {});

    img = $(src);
    if (img) {
        src = cfg.src || img.src;
    } else {
        img = document.createElement('img');
        src = src || cfg.src;
    }

    if (!src) {
        return null;
    }

    prop = isType(img.naturalWidth, 'u') ? 'width' : 'naturalWidth';
    img.alt = cfg.alt || img.alt;

    // Add the image and insert if requested (must be on DOM to load or
    // pull from cache)
    img.src = src;

    target = $(cfg.target);
    if (target) {
        target.insertBefore(img, $(cfg.insertBefore) || null);
    }

    // Loaded?
    if (img.complete) {
        if (img[prop]) {
            if (isType(cfg.success, 'f')) {
                cfg.success.call(img);
            }
        } else {
            if (isType(cfg.failure, 'f')) {
                cfg.failure.call(img);
            }
        }
    } else {
        if (isType(cfg.success, 'f')) {
            img.onload = cfg.success;
        }
        if (isType(cfg.failure, 'f')) {
            img.onerror = cfg.failure;
        }
    }

    return img;
}

export function createCustomIcon(text) {
    // Constants for styling
    const fontSize = 14; // Tamaño del texto
    const borderRadius = 4;
    const borderColor = "#000000";
    const borderThickness = 1;
    const paddingX = 4;
    const paddingY = 4;
    const backgroundColor = "#ffffff"; // Fondo similar a Airbnb
    const textColor = "#000000"; // Color del texto
    const fontFamily = "Arial, sans-serif"; // Familia de fuente

    // Create a temporary canvas element to measure text size
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Establecer el tamaño de la fuente
    context.font = `bold ${fontSize}px ${fontFamily}`;

    // Medir el tamaño del texto
    const textMetrics = context.measureText(text);
    const width = textMetrics.width;
    const height = fontSize + 2 * paddingY; // Ajustar según sea necesario

    // Establecer el tamaño del lienzo según el tamaño del texto y el relleno
    canvas.width = width + 2 * paddingX;
    canvas.height = height + 2 * paddingY;

    // Dibujar el rectángulo redondeado
    context.fillStyle = backgroundColor;
    context.beginPath();
    context.moveTo(paddingX + borderRadius, paddingY);
    context.lineTo(width + paddingX - borderRadius, paddingY);
    context.arcTo(width + paddingX, paddingY, width + paddingX, paddingY + height - 2 * paddingY, borderRadius);
    context.lineTo(width + paddingX, height + paddingY - borderRadius);
    context.arcTo(width + paddingX, height + paddingY, width + paddingX - borderRadius, height + paddingY, borderRadius);
    context.lineTo(paddingX + borderRadius, height + paddingY);
    context.arcTo(paddingX, height + paddingY, paddingX, height + paddingY - borderRadius, borderRadius);
    context.lineTo(paddingX, paddingY + borderRadius);
    context.arcTo(paddingX, paddingY, paddingX + borderRadius, paddingY, borderRadius);
    context.closePath();

    context.strokeStyle = borderColor;
    context.lineWidth = borderThickness;
    context.fill();
    context.stroke();

    // Dibujar el texto
    context.font = `bold ${fontSize - 2}px ${fontFamily}`;
    context.fillStyle = textColor;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, width / 2 + paddingX, height / 2 + paddingY);

    // Convertir el lienzo a una URL de datos
    return canvas.toDataURL();
}

export function calculateOccupiedDays(occupiedDays, minDays) {

    const occupiedDaysCopy = [...occupiedDays]; // Create a copy to avoid modifying the original
    occupiedDaysCopy.sort();

    // Add time part to the dates received from the API
    const occupiedDaysCopyWithTime = occupiedDaysCopy.map(dateString => {
        const dateWithTime = new Date(dateString);
        dateWithTime.setUTCHours(0, 0, 0, 0);
        return dateWithTime.toISOString().slice(0, -1);
    });

    // Add yestarday to the occupied days
    const occupiedDaysCopyWithYesterday = addYesterday(occupiedDaysCopyWithTime);

    const newOccupiedDays = [];

    for (let i = 0; i < occupiedDaysCopyWithYesterday.length - 1; i++) {

        const currentDay = new Date(occupiedDaysCopyWithYesterday[i]);
        const nextDay = new Date(occupiedDaysCopyWithYesterday[i + 1]);

        currentDay.setUTCHours(0, 0, 0, 0);
        nextDay.setUTCHours(0, 0, 0, 0);

        const freeDaysBetween = (nextDay - currentDay) / (1000 * 60 * 60 * 24) - 1;

        if (freeDaysBetween < minDays) {
            // Mark the intermediate days as occupied
            for (let j = 1; j <= freeDaysBetween; j++) {
                const newDay = new Date(currentDay);
                newDay.setDate(currentDay.getDate() + j);
                newOccupiedDays.push(newDay.toISOString().slice(0, -1));
            }
        }
    }

    let response = [...newOccupiedDays, ...occupiedDaysCopyWithYesterday];
    response.sort();

    return response;
}

function addYesterday(arr) {
    const sortedArray = [...arr].sort();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setUTCHours(0, 0, 0, 0);

    return [yesterday.toISOString().slice(0, -1), ...sortedArray];
}

// Function to convert the price object to the visitor currency
export function priceConverter(accommodationPrice, visitorCurrency = null) {

    const state = store.state;
    const priceAmount = accommodationPrice.amount;
    const priceCurrency = accommodationPrice.currency;

    if (!visitorCurrency) {
        visitorCurrency = state.exchange.visitorCurrency;
    }

    if (!priceAmount) {
        return { amount: 0, currency: visitorCurrency };
    }

    // Find the corresponding exchange rates in the currencyConversionTable
    const accommodationCurrencyToUSDRatio = state.exchange.currencyConversionTable.find(function (entry) {
        return entry.moneda === priceCurrency;
    }).cambio;

    const visitorCurrencyToUSDRatio = state.exchange.currencyConversionTable.find(function (entry) {
        return entry.moneda === visitorCurrency;
    }).cambio;

    // Calculate the conversion constant
    const currenciesRatio = parseFloat(accommodationCurrencyToUSDRatio) / parseFloat(visitorCurrencyToUSDRatio);

    // Calculate the price in the visitor currency
    var convertedAmount = parseFloat(priceAmount) / parseFloat(currenciesRatio);
    
    return { amount: convertedAmount, currency: visitorCurrency };
}

export function getKeyByValue(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value);
}

export function askMissingUserData(response, router) {
    const user = response.data.user;

    if (!user.pais || !user.pais.nombre || !user.tipo) {
        router.push({ name: 'register', params: { step: 2 } });
        return true;
    }
    return false;
}


export function saveRedirectUrl(route) {
    // Guarda la ruta completa en localStorage
    localStorage.setItem("redirect", route.fullPath);
}

export function redirectToSavedUrl(router) {
    let redirectUrl = localStorage.getItem("redirect");

    if (redirectUrl) {
        // Redirige al usuario a la ruta original
        router.push(redirectUrl);

        // Limpia el localStorage
        localStorage.removeItem("redirect");
    } else {
        // Redirige al usuario a la página de inicio
        router.push({ name: 'home' });
    }
}

export function formatDate(dateString) {

    // Verificar si dateString es un string y convertirlo a Date si es necesario
    const date = typeof dateString === 'string' ? moment(dateString).utcOffset(0).toDate() : dateString;

    // Obtener año, mes y día
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 porque los meses comienzan en 0
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function normalizeText(text) {
    return text.toLowerCase().replace(/-/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function normalizeUrl(url) {
    return decodeURIComponent(url)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") // Removes diacritical marks
        .replace(/\/+$/, ""); // Removes trailing slashes
}

export function getLocationFromSearchQuery(searchQuery) {

    const searchQueryArray = searchQuery.split('_');

    let city, neighborhood;
    if (searchQueryArray.length >= 3) {
        [neighborhood, city] = searchQueryArray.slice(-2);
    } else {
        [city] = searchQueryArray.slice(-1);
        neighborhood = '';
    }

    const cityName = normalizeText(city);
    const neighborhoodName = normalizeText(neighborhood);

    const location = locationsList.find(loc => {
        const normalizedLongName = normalizeText(loc.long_name);
        if (cityName && neighborhoodName) {
            return normalizedLongName.includes(cityName) && normalizedLongName.includes(neighborhoodName);
        }
        return normalizedLongName.includes(cityName);
    });
    
    return location || null;
}
