import * as types from './types';

export default {
    [types.SET_LOCATION_DESCRIPTION](state, locationDescription) {
        state.locationDescription = locationDescription;
    },
    [types.SET_LOCATION_IMAGE](state, locationImage) {
        state.locationImage = locationImage;
    },
    [types.SET_SEARCH_QUERY_LOCATION](state, searchQueryLocation) {
        state.searchQueryLocation = searchQueryLocation;
    },
};