<template>
	<div>
        <PicturesPopUp />
		<div v-if="accommodationData.tipo === 'cowork' || accommodationData.tipo === 'camper'" class="top-info">{{$t('wizard.s3.need-to-see2')}}</div>
		<div v-else class="top-info">{{$t('wizard.s3.need-to-see')}}</div>
		<hr />
		<div class="top-info">{{$t('wizard.s3.pictures-requirements')}}</div>

		<div class="row">
			<div
				class="col-xs-12 col-sm-6 col-md-3 picture-container"
				v-for="(item, i) in s3Data"
				:key="`s3-${i}`"
			>
				<span
					@click="preview[item.field] = false; accommodation.fotos[item.field] = null"
					v-if="preview[item.field]"
					class="close-pic"
				>
					<i class="fas fa-times" />
				</span>
				<label :for="`f${item.field}`">
					<input
						:id="`f${item.field}`"
						type="button"
						name="attachment"
						style="display:none;"
						:onclick="`document.getElementById('${item.field}').click();`"
					/>

					<div class="picture-inner">
						<div v-if="preview[item.field]" class="picture-selected">
							<img class="image-preview" :src="preview[item.field]" />
						</div>
						<div v-else class="picture-info">

							<img v-if="accommodationData.tipo === 'cowork'" :src="require(`@/assets/alojamiento/cowork-purple.svg`)" />
							<img v-else-if="accommodationData.tipo === 'camper'" :src="require(`@/assets/alojamiento/camper-purple.svg`)" />
							<img v-else :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />

							<div v-if="accommodationData.tipo === 'cowork'">{{$t('wizard.s3.add-photos-cowork')}}</div>
							<div v-else-if="accommodationData.tipo === 'camper'">{{$t('wizard.s3.add-photos-camper')}}</div>
							<div v-else>{{$t(item.text)}}</div>

							<div
								class="text-danger"
								v-if="!$v.accommodation.fotos[item.field].required"
							>
                                {{$t('wizard.s3.required')}}
                            </div>

						</div>

						<b-form-file
							@change="onFileChange"
							:id="item.field"
							style="display:none;"
						/>
					</div>
				</label>
			</div>
		</div>
		<FooterWizard prev next :validate="validate" />

		<b-modal
			id="error-img-modal"
			size="lg"
			ref="error-img-modal"
			:title="$t('wizard.s3.error-image-title')"
			hide-footer
			centered
		>
			<p class="my-2" v-for="error in modalData.errors" :key="error">{{error}}</p>
			<hr />
			<div class="row">
				<div class="col-12 text-right">
					<button
						class="mx-2 btn btn-outline-primary"
						id="cancel-admin-action-button"
						@click="closeModal"
					>Fechar</button>
				</div>
			</div>
		</b-modal>
	</div>
</template>


<script>
import s3Data from "./step3-data.json";
import { required } from "vuelidate/lib/validators";
import FooterWizard from "./FooterWizard";
import Compress from "compress.js";
import PicturesPopUp from '../PopUps/PicturesPopUp'

export default {
	name: "Step3Wizard",
	props: {
		data: {
			type: Array,
			default: null,
		},
		externalReference: {
			type: String,
			default: null
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
	},
	components: {
        FooterWizard,
        PicturesPopUp,
	},
	data() {
		return {
			s3Data,
			url: process.env.VUE_APP_S3_URL,
			modalData: {
				errors: [],
			},
			accommodation: {
				fotos: {
					habitacion: null,
					habitacion2: null,
					bano: null,
					cocina1: null,
					cocina2: null,
					living: null,
					comedor: null,
					otro: null,
				},
			},
			preview: {
				habitacion: null,
				habitacion2: null,
				bano: null,
				cocina1: null,
				cocina2: null,
				living: null,
				comedor: null,
				otro: null,
			},
		};
	},
	validations: {
		accommodation: {
			fotos: {
				habitacion: { required },
				habitacion2: { required },
				bano: { required },
				cocina1: { required },
				cocina2: { required },
				living: { required },
				comedor: { required },
				otro: { required },
			},
		},
	},
	methods: {
		onFileChange(e) {
			const file = e.target.files[0];
			const id = e.target.id;
			const maxSize = 1024 * 1024 * 30; // 3MB
			const formats = ["image/jpg", "image/jpeg", "image/png"];
			let validFormat = false;
			let validSize = false;
			this.modalData.errors = [];
			formats.forEach((f) => {
				if (file.type.indexOf(f) !== -1) {
					validFormat = true;
				}
			});

			if (maxSize > file.size) {
				validSize = true;
			} else {
				this.modalData.errors.push(
					this.$t("wizard.s3.error-image-maxsize")
				);
			}

			if (file && validSize && validFormat) {
				const compress = new Compress();

				compress
					.compress([...e.target.files], {
						size: 3, // the max size in MB, defaults to 2MB
						quality: 0.75, // the quality of the image, max is 1,
						maxWidth: 1920, // the max width of the output image, defaults to 1920px
						maxHeight: 1920, // the max height of the output image, defaults to 1920px
						resize: true,
					})
					.then((results) => {
						const img = results[0];
						const base64str = `${img.prefix}${img.data}`;
						const imgExt = img.ext;
						const ext = imgExt.split("/");
						const fileName = `${new Date().getTime()}.${ext[1]}`;
						this.urltoFile(base64str, fileName, imgExt).then(
							(response) => {
								this.preview[id] = URL.createObjectURL(
									response
								);
								this.accommodation.fotos[id] = response;
								console.log(this.accommodation.fotos[id], response)
							}
						);
					});
			} else {
				if (!validFormat) {
					this.modalData.errors.push(
						this.$t("wizard.s3.error-image-format")
					);
				}
				this.$refs["error-img-modal"].show();
				setTimeout(() => {
					this.preview[id] = null;
					this.accommodation.fotos[id] = null;
					console.log(this.accommodation, id);
				});
			}
		},
		async urltoFile(url, filename, mimeType) {
			return fetch(url)
				.then((res) => res.arrayBuffer())
				.then((buf) => new File([buf], filename, { type: mimeType }));
		},
		nextStep() {
			this.$emit("next", this.accommodation);
		},
		prevStep() {
			this.$emit("prev");
		},
		closeModal() {
			this.$refs["error-img-modal"].hide();
		},
	},
	computed: {
		validate: function () {
			let errors = 0;
			Object.keys(this.preview).forEach((item) => {
				if (
					this.preview[item] === null ||
					this.preview[item] === false
				) {
					errors++;
				}
			});
			return errors > 0 ? true : false;
		},
	},
	created() {
		window.scrollTo(0, 0);
		if (this.data != null) {
			Object.keys(this.preview).forEach((item, index) => {
				this.preview[item] = this.externalReference === null ? this.url + this.data[index].url : this.data[index].url;
				this.accommodation.fotos[item] = this.data[index].idgaleria;
			});
		}
	},
};
</script>



<style scoped lang="scss">
.btn-outline-primary {
	background-color: #fff;
	border-color: $hf-sec-pine !important;
	color: $hf-sec-pine;
	&:hover,
	&:active,
	&:focus {
		color: #fff;
		background-color: $hf-sec-pine !important;
		border-color: $hf-sec-pine;
	}
}
.top-info {
	padding-top: 10px;
}

.picture-container {
	padding: 10px;

	.picture-inner {
		cursor: pointer;
		height: 200px;
		width: 100%;
	}

	.picture-selected {
		height: 200px;
		border: dashed $hf-sec-pine 2px;
	}

	.close-pic {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 15px;
		border: 2px solid red;
		padding: 4px;
		border-radius: 50%;
		top: 15px;
		color: red;
		z-index: 100;
		background: #fff;
		cursor: pointer;
		width: 30px;
		height: 30px;
		text-align: center;
	}
}

.picture-info {
	display: flex;
	border: dashed $hf-sec-pine 2px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 10px 20px;
	text-align: center;

    img {
        max-width: 100px;
        fill: $hf-sec-pine;
    }
}

.image-preview {
	width: 100%;
	height: 100%;
}

label {
	width: 100%;
}
</style>