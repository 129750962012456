import countriesData from "@/assets/data/users-countries-data.json";
import highlightedCountries from "@/assets/data/accommodations-countries-data.json";

export default {
    data() {
        return {
            countriesData,
            highlightedCountries
        };
    },
    computed: {
        countriesTranslated() {
            return this.countriesData.map(item => ({
                ...item,
                name: this.$t(`countries.${item.code}`)
            }));
        },
        countriesWithCodeTranslated() {
            return this.countriesData.map(item => ({
                ...item,
                name: this.$t(`countries.${item.code}`) + ` (+${item.value})`
            }));
        },
        highlightedCountriesTranslated() {
            return this.highlightedCountries.map(item => ({
                ...item,
                name: this.$t(`countries.${item.code}`) + ` (+${item.value})`
            }));
        }
    }
};
