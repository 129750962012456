<template>
    <div class="featured">

        <!-- featured__pictures -->
        <div class="featured__pictures">
            <router-link :to="{ name: 'detalle-alojamiento', params: { idalojamiento: id } }">
                <CarouselList :idAlojamiento="id" :from="from" :externalReference="accommodation.external_reference"></CarouselList>
            </router-link>
        </div>

        <!-- Body -->
        <div class="featured__body">

            <!-- Favorites icon -->
            <div class="featured__heart">
                <img v-if="isLoggedIn && wish" @click="wishListFeatured(id)" src="@/assets/corazon.svg" />
                <img v-else-if="isLoggedIn" @click="wishListFeatured(id)" src="@/assets/corazon-borde.svg" />
                <img v-else  @click="warning()" src="@/assets/corazon-borde.svg" />
            </div>

            <!-- Title -->
            <div class="featured__title">
                <router-link :to="'/alojamiento/'+id"> 
                    {{ title | capitalFirst }} 
                </router-link>
            </div>

            <!-- Price -->
            <div class="features__price">   

                <div v-if="showMonthlyPrice">
                    {{ monthlyPrice | convertAndFormatPrice }}
                    {{ $t("accommodation.per-month") }}
                </div>
                
                <div v-if="showDailyPrice" :class="{'featured__price--small': showMonthlyPrice }" >
                    {{ dailyPrice | convertAndFormatPrice }}
                    {{ $t("accommodation.per-day") }}
                </div>

            </div>

            <!-- Location -->
            <div class="featured__address">
                <i class="fas fa-map-marker-alt" />
                <span>{{direccion.split(",")[1]}}</span>
            </div>
        </div>
        
    </div>
</template>

<script>
import CarouselList from "@/components/Carousel/CarouselList";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import priceMixin from "@/mixins/priceMixin";
import accommodation from "@/store/modules/accommodation";
export default {
    name: "AccommodationItem",
    props: [
        "id", 
        "nombre", 
        "direccion", 
        "accommodation", 
        "from"
    ],
    mixins: [ priceMixin ],
    
    data() {
        return {
            wish: false,
        };
    },
    
    components: {
        CarouselList
    },
    
    computed: {
        ...mapGetters(["isLoggedIn"]),
        ...mapState(["user"]),
        showMonthlyPrice() {
            return this.accommodation.modalidad_tiempo === 'mensual' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        showDailyPrice() {
            return this.accommodation.modalidad_tiempo === 'diario' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        title() {
            // The title is built with the categoría + tipo - alojamientoid
            return this.$t('accommodation.title.' + this.accommodation.tipo + '-' + this.accommodation.categoria)
        }
    },
    
    methods: {
        
        wishListFeatured(id) {
            if (this.isLoggedIn) {
                let alojamientodata = {
                    alojamiento_idalojamiento: id
                };
                axios(
                    {
                        url: process.env.VUE_APP_API_URL + "wishList/change",
                        data: alojamientodata,
                        method: "POST"
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                ).then(response => {
                    this.wish = response.data.estado;
                });
            } else {
                this.warning();
            }
        },
        
        warning() {
            if (this.$i18n.locale === "es") {
                alert(
                    "Debes iniciar sesión para agregar este alojamiento a favoritos"
                );
            } else {
                alert("You must login to add this accommodation to favorites");
            }
        },
        
    },
    
    created() {
        
        if (this.isLoggedIn) {
            axios
                .get(process.env.VUE_APP_API_URL + "wishList/checkWish/" + this.id)
                .then(response => { this.wish = response.data.estado; });
        }
        
    }
};
</script>

<style scoped lang="scss">

.featured {
    border: 1px solid rgba(0, 0, 0, 0.22);
    border-radius: $border-radius-new;
    margin: 10px;
    padding: 10px;
}

.featured__title {
    a {
        color: $hf-sec-pine;
    }
    padding: 5px 45px 5px 10px;
    height: 75px;
    overflow: hidden;
}

.features__price {
    height: 60px;
    color: $grey-75;
    font-weight: bold;
    font-size: 1.1em;
    padding: 5px 45px 5px 10px;

    .featured__price--small {
        font-size: 0.8em;
    }
}

.featured__address {
    height: 35px;
    color: $grey-75;
    //font-weight: lighter;
    bottom: 30px;
    padding: 5px 45px 5px 10px;

    .fa-map-marker-alt {
        font-size: 1.2em;
        margin-right: 10px;
    }
}

.featured__heart {
    position: absolute;
    right: 20px;
    margin-top: 10px;
}
    
.featured__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.featured__pictures {
    padding: 10px 10px 0 10px;
}

</style>