<template>
    <div class="container">

        <!-- Accommodation -->
        <div v-if="accommodation">
            
            <div class="title">{{ title | capitalFirst }}</div>
            <div class="accommodation-id">{{ $t("accommodation.accommodation-id") }}: {{ accommodation.idalojamiento }}</div>
            
            <!-- Reservation datasheet -->
            <div class="row mx-0">

                <!-- Pictures-->
                <div class="col-sm-12 col-lg-8 px-0">
                    <CarouselList
                        :idAlojamiento="this.$route.params.idalojamiento"
                        :externalReference="accommodation.external_reference"
                        :photos="accommodation.photos"
                    />
                </div>
                
                <!-- Price, calendar, hosts, visit, simulation, reserve  -->
                <div class="col-sm-12 col-lg-4 info-container">
                    <BookingDetails 
                        :image="user.imagen"
                        :accommodation="accommodation" 
                    />
                </div>
                
            </div>

            <!-- ADMIN ONLY DATA -->
            <AdminBar v-if="isAdmin" :data="accommodation" />
            
            <!-- Detalle Items -->
            <hr class="mobile-only">
            <DetalleAlojamientoItems :accommodation="accommodation" />
            <hr class="mobile-only">
            <DetalleAlojamientoTabs :data="accommodation" />
            <hr class="mobile-only">
            <DetalleAlojamientoAccordion :data="accommodation" />

            <DetalleAlojamientoLocation :data="accommodation" />
            
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState, mapGetters } from "vuex";
import CarouselList from "@/components/Carousel/CarouselList";
import BookingDetails from "@/components/DetalleAlojamiento/BookingDetails";
import GmapCustomMarker from "vue2-gmap-custom-marker";

import DetalleAlojamientoItems from "@/components/DetalleAlojamiento/DetalleAlojamientoItems";
import DetalleAlojamientoTabs from "@/components/DetalleAlojamiento/DetalleAlojamientoTabs";
import DetalleAlojamientoAccordion from "@/components/DetalleAlojamiento/DetalleAlojamientoAccordion";
import DetalleAlojamientoLocation from "@/components/DetalleAlojamiento/DetalleAlojamientoLocation";
import DetalleVideo from "@/components/DetalleAlojamiento/DetalleVideo";
import AdminBar from "../../components/Admin/AdminBar";

export default {

	name: "DetalleAlojamiento",
	components: {
		BookingDetails,
		DetalleAlojamientoItems,
		DetalleAlojamientoTabs,
		DetalleAlojamientoAccordion,
		DetalleAlojamientoLocation,
		DetalleVideo,
		AdminBar,
		"gmap-custom-marker": GmapCustomMarker,
		CarouselList,
	},
	data() {
		return {
            accommodationFetched: false,
		};
	},
	methods: {
        ...mapActions('accommodation', [
            "fetchAccommodationById"
        ]),
        
        handleNewVisitor()
        {
            let instance = axios.create({ timeout: 1000 });
            delete instance.defaults.headers.common['Authorization'];
            instance.get('https://api.ipify.org/?format=json')
                .then((response) => {
                    let visitData = localStorage.getItem('visit_data');

                    if (visitData === null || visitData === undefined) {
                        visitData = [];
                        let acc = {
                            ip: response.data.ip,
                            date: moment(),
                            id: this.accommodation.idalojamiento
                        }
                        visitData.push(acc);
                        localStorage.setItem('visit_data', JSON.stringify(visitData))
                        axios.post(`${process.env.VUE_APP_API_URL}accommodations/add-visit`, { id: this.accommodation.idalojamiento });
                    } else {
                        let JsonData = JSON.parse(visitData);
                        let find = JsonData.find((item) => item.id === this.accommodation.idalojamiento);
                        if (find === undefined) {
                            let acc = {
                                ip: response.data.ip,
                                date: moment(),
                                id: this.accommodation.idalojamiento
                            }
                            JsonData.push(acc);
                            axios.post(`${process.env.VUE_APP_API_URL}accommodations/add-visit`, { id: this.accommodation.idalojamiento });
                        }
                        JsonData.forEach((item) => {
                            if (item.id === this.accommodation.idalojamiento) {
                                let date = moment(item.date);
                                let endtime = moment(item.date).add(1, 'hours');
                                if (endtime.isBefore(moment())) {
                                    item.date = moment(),
                                        axios.post(`${process.env.VUE_APP_API_URL}accommodations/add-visit`, { id: this.accommodation.idalojamiento });
                                }
                            }
                        })
                        localStorage.setItem('visit_data', JSON.stringify(JsonData))
                    }
                })
                .catch(({ response }) => {

                })
        }
    },
    computed: {
        ...mapState('accommodation', [
            "accommodation",
        ]),
        ...mapState(["user"]),
        ...mapGetters(["isAdmin"]),

        title() {
            // The title is built with the categoría + tipo - alojamientoid
            return this.$t('accommodation.title.' + this.accommodation.tipo + '-' + this.accommodation.categoria)
        }

    },
    
    created() {

        window.scrollTo(0, 0);

        // Fetch accommodation
        const accommodationId = this.$router.currentRoute.params.idalojamiento;
        this.fetchAccommodationById(accommodationId);

        // TODO: see what is this
        this.handleNewVisitor();
        
    },
};
</script>

<style scoped lang="scss">

.title {
    font-size: 1.2rem;
    padding: 12px 0 5px 0;
}

.accommodation-id {
    font-size: 0.8rem;
    padding-bottom: 5px;
}

.info-container {
    
    padding: 15px 0;
    margin-bottom: 10px;

    @media (min-width: $md) {
        padding: 0 0 0 15px;
        margin-bottom: 0;
    }
}

</style>
