import exchangeModule from './modules/exchange';
import accommodationModule from './modules/accommodation';
import adminReservationModule from './modules/adminReservation';
import filterModule from './modules/filter';
import restrictionModule from './modules/externalIcal';
import searchModule from './modules/search';
import searchQueryModule from './modules/searchQuery';
import simulatorModule from './modules/simulator';
import reservationModule from './modules/reservation';
import appVersionCheck from "@/store/modules/appVersionCheck";
import bankData from "@/store/modules/bankData";
import user from "@/store/modules/user";

export default {
    exchange: exchangeModule,
    accommodation: accommodationModule,
    adminReservation: adminReservationModule,
    filter: filterModule,
    restriction: restrictionModule,
    search: searchModule,
    searchQuery: searchQueryModule,
    simulator: simulatorModule,
    reservationNew: reservationModule,
    appVersionCheck: appVersionCheck,
    bankData: bankData,
    userNew: user
};