
<template>
    <div>
		
        <!-- Search box -->
        <form class="searchbox-home">
			
            <!-- Title, subtitle and close button-->
            <div>

                <!-- Title and subtitle -->
                <h1 class="search-box-title">{{ title }}</h1>
                <p class="search-box-subtitle"> {{ subtitle }}</p>
                
			</div>

            <!-- Location and date range -->
			<div class='search-fields-group'>

                <!-- Location field -->
				<div class='col-12 mb-2 px-0'>
                    <multiselect 
                        v-model="direccion" 
                        track-by="comuna" 
                        :placeholder="$t('search.select-city')"
                        class="location-multiselect"
                        :open-direction="'bottom'"
                        :options="locationsList" 
                        :custom-label="customLabel" 
                        :searchable="true" 
                        :allow-empty="false"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel=""
                    >
                        <template slot="noResult" >
                            <router-link :to="{ name: 'contact' }" class="no-results">
                                {{ $t('search.no-result') }}
                            </router-link>
                        </template>

                        <template slot="singleLabel" slot-scope="{ option }">
                            <span><i class="fas fa-map-marker-alt mr-2"></i></span>
                            <span v-if="option.country && option.comuna"> {{ option.country }} | <strong>{{ option.comuna }}</strong></span>
                            <span v-else >{{ $t('search.select-city') }}</span>
                        </template>

                    </multiselect>

                    <!-- Validation message -->
                    <div v-if="direccion_error" class="col-12 text-danger" >
                        <span>{{$t('search.location-validation')}}</span>
                    </div>
				</div>

                <DatePicker showAnyDateCheckbox />

                <div class='mb-2'>

                    <!-- Search button -->
                    <button class="col-12 btn-search" @click.prevent="search()">
                        {{ $t('search.search') }}
                    </button>

                </div>
                
			</div>
            
		</form>
        
        <!-- Location and Dates Modal -->
        <b-modal
            v-model="showLocationDatesModal" 
            class="modal"
            id="modal-location-&-dates"
            hide-footer
            size="md"
            @ok="search()"
            :ok-title="$t('search.search')"
        >
            <!-- Close button -->
            <template #modal-header-close>
                <button type="button" class='close-button'>
                    <i class="fas fa-times"></i>
                </button>
            </template>
            
            <!-- Template title -->
            <template #modal-title>
                <div class="px-3">
                    {{ $t('search.advanced-filters') }}
                </div>
            </template>

            <div class="p-3">

                <div class='search-fields-group'>

                    <!-- Location field -->
                    <div class='col-12 mb-2 px-0'>
                        <multiselect
                            v-model="direccion"
                            track-by="comuna"
                            :placeholder="$t('search.select-city')"
                            class="location-multiselect"
                            :open-direction="'bottom'"
                            :options="locationsList"
                            :custom-label="customLabel"
                            :searchable="true"
                            :allow-empty="false"
                            selectLabel=""
                            deselectLabel=""
                            selectedLabel=""
                        >
                            <template slot="noResult" >
                                <router-link :to="{ name: 'contact' }" class="no-results">
                                    {{ $t('search.no-result') }}
                                </router-link>
                            </template>

                            <template slot="singleLabel" slot-scope="{ option }">
                                <span><i class="fas fa-map-marker-alt mr-2"></i></span>
                                <span v-if="option.country && option.comuna"> {{ option.country }} | <strong>{{ option.comuna }}</strong></span>
                                <span v-else >{{ $t('search.select-city') }}</span>
                            </template>

                        </multiselect>

                        <!-- Validation message -->
                        <div v-if="direccion_error" class="col-12 text-danger" >
                            <span>{{$t('search.location-validation')}}</span>
                        </div>
                    </div>

                    <DatePicker showAnyDateCheckbox />

                    <div class='mb-2'>

                        <!-- Search button -->
                        <button class="col-12 btn-search" @click.prevent="search()">
                            {{ $t('search.search') }}
                        </button>

                    </div>

                </div>

            </div>

        </b-modal>

		<!-- Filters Modal -->
		<b-modal 
            class="modal" 
            id="modal-filtros" 
            ok-only 
            size="lg" 
            @ok="search()" 
            :ok-title="$t('search.search')"
        >
            <!-- Close button -->
            <template #modal-header-close>
                <button type="button" class='close-button'>
                    <i class="fas fa-times"></i>
                </button>
            </template>

            <!-- Template título -->
            <template #modal-title>
                <div class="px-3">
                    {{ $t('search.advanced-filters') }}
                </div>
            </template>

            <div class="p-3">
                
                <!-- Price and guests -->
				<div class="row">

                    <!-- Price slider -->
					<div class="col-md-6">
						<div class="label">{{ $t(getSliderText) }}</div>

                        <vue-slider
                            :interval="sliderInterval"
                            v-model="priceRange"
                            :tooltip-formatter="sliderTooltip(priceRange)"
                            tooltip='active'
                            :min="sliderScaleMin"
                            :max="sliderScaleMax"
						/>
<!--                        :order="false"-->
                        

                        <div class="col-12 text-center">
                            {{ $t('search.price.from') }}: {{ priceRange[0] }} {{ $t('search.price.to') }}: {{ priceRange[1] }} {{ visitorCurrency }}
                        </div>

                    </div>

                    <!-- Guest field -->
					<div class="col-md-6 mt-4 mt-md-0">
						
                        <div class="label">{{ $t('search.qty-guests') }}</div>

                        <select
                            class="form-control"
                            name="nro_huespedes"
                            id="nro_huespedes"
                            v-model="nro_huespedes"
                        >
                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>

					</div>

				</div>

				<!-- Type and categories filters -->
				<div class="row filters-group">
					
                    <!-- Type filter -->
                    <div class="col-md-6">

						<div class="label">{{ $t('search.type-accommodation') }}</div>

                        <multiselect 
                            v-model="selectedType" 
                            :options="options" 
                            :multiple="true"
                            track-by="name"
                            :placeholder="$t('multiselect.placeholder')"
                            label="name"
                            :selectLabel="$t('multiselect.pick')"
                            :hide-selected="true"
                        >
                            <template slot="noOptions">
                                <div class="option__desc">Lista Vazia</div>
                            </template>
                        </multiselect>

					</div>

                    <!-- Category filter -->
					<div v-if="selectedType.length > 0" class="col-md-6">

                        <div class="label">{{ $t('search.category-accommodation') }}</div>                    

                        <multiselect 
                            v-model="selectedCategories" 
                            :options="optionsCategory" 
                            :multiple="true"
                            track-by="name"
                            :placeholder="$t('multiselect.placeholder')"
                            label="name"
                            :selectLabel="$t('multiselect.pick')"
                            :hide-selected="true"
                        >
                            <template slot="noOptions">
                                <div class="option__desc"> {{$t("multiselect.empty")}} </div>
                            </template>

                        </multiselect>

					</div>

				</div>

                <div class="row filters-group">
                    <!-- Rooms number field -->
                    <div v-if="showRoomsFilter" class="col-md-6 mt-4 mt-md-0">
                        <div class="label">{{ $t('search.rooms') }}</div>
                        <select
                            class="form-control"
                            name="rooms_number"
                            id="rooms_number"
                            v-model="rooms_number"
                        >
                            <option :value="0">{{ $t('search.studio') }}</option>
                            <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                    </div>
                </div>
                
                <!-- Show optional filters button -->
				<div class="label more-filters filters-group" @click="toogleOptionalFilters">
					
                    {{ $t('search.more-filters') }}
					
                    <button class="btn btn-link shadow-none text-decoration-none font-weight-bold">
                        <span v-show="!show_more_filters"><i class="fa fa-chevron-down"></i></span>
                        <span v-show="show_more_filters"><i class="fa fa-chevron-up"></i></span>
					</button>

				</div>

                <!-- more filters list -->
				<div v-if="show_more_filters" class="d-flex flex-column flex-wrap overflow-auto">
					<div v-for="(filtro_piece, index) in filters_piece_list" :key="index">
						<input
							type="checkbox"
							:name="index"
							:id="'indM-' + index"
							:value="index"
							v-model="filters_piece_selected"
						/>
						<label :for="'indM-' + index" class="filtro_booleano ml-2">{{ getTextPiece(index) }}</label>
					</div>
				</div>
				
			</div>

            <!-- Botón de limpiar filtros -->
            <template #modal-footer="{ ok }">
                <b-button @click="clearFilters" variant="outline-secondary">
                    {{ $t('search.clear-filters') }}
                    <span v-if="appliedFiltersCount > 0" class="badge badge-danger">{{ appliedFiltersCount }}</span>
                </b-button>
                <b-button @click="ok()" v-if="ok"> {{ $t('search.apply-filters') }}</b-button>
            </template>
            
		</b-modal>

	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import * as moment from "moment";
import categorias from "./Category.json";
import { locationsList } from "@/constants/locationsList";
import { priceConverter } from "@/Utils/Utils";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import { normalizeText } from "@/Utils/Utils";

export default {
	name: "Search",
	components: {
        VueSlider,
        Multiselect,
        DatePicker
	},
	data() {
		return {
            locationsList: locationsList,
			categorias,
			tipo_alojamiento: ["entero", "privado", "compartido"],
			categoria_selected: [],
			dates_required: false,
            date: 'null',
			nro_huespedes: 0,
            rooms_number: null,
			precio_max: null,
			precio_min: null,
            sliderScaleMin: 0,
			placeholder: "Elige la localidad",
			filtros_opcionales_selected: [],
			filters_piece_selected: [],
			show_more_filters: false,
			direccion_error: false,
			currency: "$",
			priceRange: [],
			full_name: "",
			direccion: {},
			from_address: {},
			filtros_booleanos: [],
			observarPicker: [],
			dailyMode: 1,
			toggle_filters: {
				state: true,
				value: "-"
			},
            currency_code: "clp",
            options: [],
            optionsCategory: categorias,
            selectedType: [],
            selectedCategories: [],
            northeast:"",
            southwest:"",
            colorVariable: '#495057',
            selectedCity: "",
            showLocationDatesModal: false,
            useMonthlyPriceRange: true
            
		};
	},
	computed: {

        ...mapState('exchange', [
            "visitorCurrency",
        ]),
        
        ...mapState('search', [
            "checkInDate",
            "checkOutDate",
            "searchLocation",
        ]),

        showRoomsFilter() {
            return this.selectedType.some(type => type.value === 'entero') 
                // && (
                //     this.selectedCategories.some(category => category.value.includes('casa'))
                //     || this.selectedCategories.some(category => category.value.includes('departamento')
                //     )
                // )
                ;
        },
        
        title() {
            // Check if the current component is 'SearchQuery'
            if (this.$route.name === 'search-query') {
                const searchQuery = this.$route.params.search_query;

                const searchQueryArray = searchQuery.split(/[_]/);
                const title = searchQueryArray[0].replace(/-/g, ' ') + ' ' + this.searchLocation.long_name;
                return title.toUpperCase();
            }

            // If it's not the 'SearchQuery' component, use the translation file
            return this.$t('search.search-accommodation');
        },
        
        subtitle() {
            if (this.$route.name === 'search-query') {
                return '';
            } else {
                return this.$t('search.search-accommodation-sub');
            }
        },

        sliderInterval() {
            let divisor = this.useMonthlyPriceRange ? 1 : 10;

            switch (this.visitorCurrency) {
                case "GBP":
                case "EUR":
                    return 100 / divisor;
                case "CLP":
                    return 100000 / divisor;
                case "USD":
                default:
                    return 100 / divisor;
            }
        },

        sliderScaleMax() {
            let divisor = this.useMonthlyPriceRange ? 1 : 10;

            switch (this.visitorCurrency) {
                case "GBP":
                case "EUR":
                    return 3000 / divisor;
                case "CLP":
                    return 3000000 / divisor;
                case "USD":
                default:
                    return 3000 / divisor;
            }
        },

		filters_piece_list() {
			return this.$t("search.filter_list");
		},

        appliedFiltersCount() {
            const count =  (this.nro_huespedes > 0 ? 1 : 0) +
                this.filtros_opcionales_selected.length +
                this.filters_piece_selected.length +
                this.selectedType.length +
                this.selectedCategories.length +
                (this.rooms_number != null ? 1 : 0)
            ;
            
            this.setAppliedFiltersCount(count);
            
            return count;
        },

		getSliderText() {
			if (this.checkInDate !== 'null' && this.checkOutDate !== 'null') {
				const a = moment(this.checkInDate);
				const b = moment(this.checkOutDate);
				const duration = moment.duration(b.diff(a));
				if (duration.asDays() < 30) {
                    this.useMonthlyPriceRange = false;
                    return "search.day-price";
                }
			}
            this.useMonthlyPriceRange = true;
			return "search.monthly-price";
		},

	},
	watch: {
        
        showRoomsFilter(newValue) {
            if (!newValue) {
                this.rooms_number = null;
            }
        },
        
        title: {
            immediate: true,
            handler(newVal) {
                document.title = this.$options.filters.capitalWords(newVal);
            }
        },

        useMonthlyPriceRange(newValue, oldValue) {
          this.resetPriceRange();
          this.updateAppliedFiltersCount();
        },
        
        // Reset the price rage when the currency is switched
        visitorCurrency(newValue, oldValue) {
            this.resetPriceRange();
        },

        // If the user select a new location, set the location in the store
        direccion(newDireccion) {
            if (newDireccion && newDireccion.comuna) {
                this.setSearchLocation(newDireccion);
            }
        },
        
        searchLocation: {
            immediate: true,
            handler(newLocation) {
                if (newLocation && newLocation.comuna && (this.$route.name === 'search' || this.$route.name === 'search-query')) {
                    this.direccion = newLocation;
                }
            }
        },

        // Refactor this
        selectedType(newValue, oldValue) {

            let optionsCategoryList = [];

            newValue.forEach((item) => {
                categorias.forEach((opItem) => {
                    if(opItem.owner.indexOf(item.value) !== -1) {
                        let s = optionsCategoryList.find((cl) => opItem.value === cl.value)
                        if(s === undefined) {
                            optionsCategoryList.push(opItem)
                        }
                    }
                })
            })

            const availables = []
            optionsCategoryList.forEach((item) => {
                let s = this.selectedCategories.find((f) => f.name === item.name);
                if(s !== undefined) {
                    availables.push(s)
                }
            })
            this.selectedCategories = availables;
            this.optionsCategory = optionsCategoryList;

            this.updateAppliedFiltersCount();
        },
        
		nro_huespedes(newValue, oldValue) {
			if (newValue <= 0 && newValue != "") {
				this.nro_huespedes = 1;
			}
            this.updateAppliedFiltersCount();
		},

		filters_piece_selected(newValue, oldValue) {
			localStorage.setItem("filter_piece", newValue);
            this.updateAppliedFiltersCount();
		},

		filtros_opcionales_selected(newValue, oldValue) {
			localStorage.setItem("filter_piece_optional", newValue);
            this.updateAppliedFiltersCount();
		},

		tipo_alojamiento(newValue, oldValue) {
			localStorage.setItem("tipo_alojamiento", newValue);
            this.updateAppliedFiltersCount();
        },
	},
	created() {
        this.resetPriceRange();

        // Set the select to the search location
        // if (this.$route.name == 'search' || this.$route.name === 'search-query') {
        //     this.direccion = this.searchLocation;
        // }
        
        // Refactor this
        this.translateTypes();
        let options = [
            {
                name: this.$t('search.types.entero'),
                value: "entero"
            },
            {
                name: this.$t('search.types.privado'),
                value: "privado"
            },
            {
                name: this.$t('search.types.compartido'),
                value: "compartido"
            }
        ]

        // Refactor this
        this.options = options
        this.selectedType = []

        // Refactor this
		if (localStorage.getItem("filter_piece")) {
			this.filters_piece_selected = [];
			this.filters_piece_selected = localStorage
				.getItem("filter_piece")
				.trim()
				.split(",");
		}

        // Refactor this
		if (localStorage.getItem("filter_piece_optional")) {
			this.filtros_opcionales_selected = [];
			this.filtros_opcionales_selected = localStorage
				.getItem("filter_piece_optional")
				.trim()
				.split(",");
		}
	},
	methods: {

        ...mapActions('search', [
            'setSearchLocation'
        ]),
        
        ...mapActions('filter', [
            'setAppliedFiltersCount',
        ]),

        clearFilters() {
            this.filters_piece_selected = [];
            this.selectedType = [];
            this.selectedCategories = [];
            this.nro_huespedes = 1;
            this.rooms_number = null;
            // this.resetPriceRange();
            
            // Close the more filter menu
            this.show_more_filters = false;
        },
        
        resetPriceRange() {
            // Set the initial values for the price range filter
            this.priceRange[0] = this.sliderScaleMin;
            this.priceRange[1] = this.sliderScaleMax;
        },

        translateTypes(){

            this.options.forEach((item) => {
                item.name = this.$t(`search.types.${item.value}`)
            })

            this.selectedType.forEach((item) => {
                item.name = this.$t(`search.types.${item.value}`)
            })

            this.selectedCategories.forEach((item) => {
                item.name = this.$t(item.translated)
            })

            this.optionsCategory.forEach((item) => {
                item.name = this.$t(item.translated)
            })
        },

		toggle_filter() {
			if (this.toggle_filters.state) {
				this.toggle_filters.state = false;
				this.toggle_filters.value = "+";
			} else {
				this.toggle_filters.state = true;
				this.toggle_filters.value = "-";
			}
		},

		getSelectedFilters(selected, list) {
			let filters = [];

			selected.forEach(value => {
				let filter_name = "";

				Object.keys(list).forEach((name, index) => {
					if (name === value) {
						filter_name = list[name];

						filter_name =
							filter_name.charAt(0).toUpperCase() +
							filter_name.slice(1);

						filters.push(filter_name);
					}
				});
			});

			return filters.join(" - ");
		},

		getTextPiece(key) {
			return this.filters_piece_list[key];
		},

        customLabel (direccion) {
            return `${direccion.country} | ${direccion.comuna}`
        },

		closeSearch() {
			this.$emit("closeSearch");
		},

		search() {
            
            if (!this.$v.$invalid) {
                
                this.$v.$touch(); // validate form
                
                this.direccion_error = false;
                
                if (this.direccion.long_name === undefined || this.direccion.long_name === null) {
                    this.direccion_error = true;
                }
                
                if (this.direccion_error) {
                    return;
                }

                this.dailyMode = 0;
                if (this.checkInDate !== null && this.checkOutDate !== null) {
                    const init = moment(this.checkInDate);
                    const end = moment(this.checkOutDate);
                    const duration = moment.duration(end.diff(init));
                    if (duration.asDays() < 30) {
                        this.dailyMode = 1;
                    }
                } 

                // Convert price range to USD because the api make the search in USD
                const fromPrice = priceConverter({ 'amount': this.priceRange[0], 'currency': this.visitorCurrency }, 'USD').amount;
                let toPrice = priceConverter({ 'amount': this.priceRange[1], 'currency': this.visitorCurrency }, 'USD').amount;

                // If the max price selected is the same as the max range, set it to 0 to avoid filtering
                toPrice = toPrice === this.sliderScaleMax ? 0 : toPrice;
                
                var params = {
                    latitud: this.direccion.latitud,
                    longitud: this.direccion.longitud,
                    
                    northeast: `${this.direccion.northeast}`,
                    southwest: `${this.direccion.southwest}`,
                    // northeast: {
                    //     lat: this.direccion.northeast.lat,
                    //     lng: this.direccion.northeast.lng
                    // },
                    // southwest: {
                    //     lat: this.direccion.southwest.lat,
                    //     lng: this.direccion.southwest.lng
                    // },
                    
                    country: this.direccion.country,
                    comuna: this.direccion.comuna,
                    long_name: this.direccion.comuna,
                    type_route: this.direccion.type_route,
                    fecha_inicio: this.checkInDate,
                    fecha_termino: this.checkOutDate,
                    precio_min: fromPrice,
                    precio_max: toPrice,
                    tipo_alojamiento: [],
                    numero_huespedes: this.nro_huespedes,
                    rooms_number: this.rooms_number,
                    direccion_completa: `${this.direccion.comuna},${this.direccion.country}`,
                    page: 1,
                    polygon: this.direccion.polygon
                };

                // Add the selected types to the parameters
                if (this.selectedType.length > 0) {
                    params["tipo_alojamiento"] = [];
                    this.selectedType.forEach((item) => {
                        params["tipo_alojamiento"].push(item.value)
                    })
                }
    
                // Add the selected categories to the parameters
                if (this.selectedCategories.length > 0) {
                    params["categoria"] = [];
                    this.selectedCategories.forEach((item) => {
                        params["categoria"].push(item.value)
                    })
                } 
    
                // Add the optional filters to the parameters
                if (this.filtros_opcionales_selected) {
                    for (var filtro in this.filtros_opcionales_selected) {
                        params[this.filtros_opcionales_selected[filtro]] = 1;
                    }
                }
    
                if (this.filters_piece_selected) {
                    for (var filtro in this.filters_piece_selected) {
                        params[this.filters_piece_selected[filtro]] = 1;
                    }
                }

                // Close search menu
                this.$emit('closeSearchMenu');
                this.show_more_filters = false;

                this.$router.push({ name: 'search', query: params });

                this.showLocationDatesModal = false;

            } else {
                console.log('invalid form')
            }
		},

		toogleOptionalFilters() {
			this.show_more_filters = !this.show_more_filters;
		},

		sliderTooltip(priceRange) {
			return priceRange[0] + " " + this.visitorCurrency + " - " + priceRange[1] + " " + this.visitorCurrency;
		},

        updateAppliedFiltersCount() {
            const count = this.appliedFiltersCount;
            this.setAppliedFiltersCount(count);
        }
	},
	validations: {
		direccion: { required }
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>

.searchbox-home {

    .date-check {

        margin: 0 15px;
        height: 56px;
        text-align: left;
        .btn-check {
            width: 25px;
            height: 100%;
            accent-color: $hf-sec-pine;
        }
        
        .label {
            line-height: 1;
            color: #495057;
        }
    }

    .search-box-title {  
        margin-top: 0;      
        font-weight: 600;
		font-size: 1.5em;
        text-align: center;
    }

	.search-box-subtitle {
		font-size: 1em;
		margin-bottom: 0.5rem;
		color: #555;
	}

    .text-danger {
        min-height: 24px;;
    }

	.form-control {
		padding: 24px;
	}

    .form-control::placeholder {
       font-style: italic;
    }

    .search-fields-group {
        position: relative;
    }

    .btn-search {
        height: 50px;;
		background: $hf-sec-pine;
		border-radius: 6px;
		border: 1px solid;
		color: #ffffff;
		font-size: 24px;
        justify-content: center;
	}

}

.modal {
    
    .modal-body > div {
        padding: 15px 0 !important;
    }

    .close-button {
        font-size: 30px;
    }

    .label {
        font-weight: bold;
        margin-bottom: 0.5rem;
        padding: 2px 0;
    }
    
    .more-filters {
        cursor: pointer;

        button {
            color: #000 !important;
        }
    }
    
    .badge {
        font-size: 0.8rem;
        position: absolute;
        bottom: 0;
        top: auto;
        margin-bottom: 43px;
    }

    .filters-group {
        margin-top: 1.5em;
    }

    .btn-search {
        height: 50px;;
        background: $hf-sec-pine;
        border-radius: 6px;
        border: 1px solid;
        color: #ffffff;
        font-size: 24px;
        justify-content: center;
    }

    .date-check {

        margin: 0 15px;
        height: 56px;
        text-align: left;
        .btn-check {
            width: 25px;
            height: 100%;
            accent-color: $hf-sec-pine;
        }

        .label {
            line-height: 1;
            color: #495057;
        }
    }

}

::v-deep .no-results {
    color: $hf-sec-pine;
    text-decoration: underline;
    &:hover {
        text-decoration: underline !important;
    }
}


</style>

<style lang="scss">

// Price slider
.vue-slider {
    .vue-slider-rail {

        background-color: #bebebe !important;

        .vue-slider-process,
        .vue-slider:hover {
            background-color: $hf-sec-pine !important;
        }

        .vue-slider-dot-handle,
        .vue-slider-dot-handle:hover {
            box-shadow:none;
            outline: $hf-sec-pine;
            border-color: $hf-sec-pine;
        }
    }
}

.datepicker {
    z-index: 1021 !important;
}

// Next month button
.next--mobile {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background: $hf-sec-pine;
    height: $see-more-button-height;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1021 !important;
}

.location-multiselect {
    //border: 1px solid $grey-10;
    .multiselect__option--highlight {
        background: $hf-sec-pine;
    }
    .multiselect__tags {
        height: 100%;
        padding: 10px 0px 4px 16px;    
    }
    .multiselect__select {
        padding: 9px 0 0 0;
        z-index: 1;
    }
}

</style>