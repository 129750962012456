import { priceConverter } from "@/Utils/Utils";

const filters = {

    // TODO: replace by convertAndFormatPrice and remove
    priceFormatter(value, currency) {
        // If value is 0, simply show the number 0 with the currency symbol
        if (value === 0) return `${currency} 0`;

        // If value is not a valid number, return empty string
        if (!value || isNaN(value)) return '';

        // If the currency is CLP, format without decimals and with dots as thousands separators
        if (currency === 'CLP') {
            return `CLP ${formatNumberWithDots(parseInt(value))}`;
        } else {
            // Otherwise, format with two decimals and commas as thousands separators
            return `${currency} ${parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
    },

    // Custom filter to convert the accommodation price to the visitor currency and format it
    convertAndFormatPrice(price) {

        // Convert the price to the visitor currency
        const convertedPrice = priceConverter(price);

        const amount = convertedPrice.amount;
        const currency = convertedPrice.currency;
        
        // If amount is 0, simply show the number 0 with the currency symbol
        if (amount === 0) return `${currency} 0`;

        // If amount is not a valid number, return empty string
        if (!amount || isNaN(amount)) return '';

        // If the currency is CLP, format without decimals and with dots as thousands separators
        if (currency === 'CLP') {
            return `CLP ${formatNumberWithDots(parseInt(amount))}`;
        } else {
            // Otherwise, format with two decimals and commas as thousands separators
            return `${currency} ${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
    },

    // Custom filter to convert string to uppercase
    uppercase(value) {
        // If value is an empty string, return an empty string
        if (!value) return '';
        // Convert the string to uppercase
        return value.toUpperCase();
    },

    // Convert the first letter to uppercase and the others to lowercase
    capitalFirst(value) {
        // If value is an empty string, return an empty string
        if (!value) return '';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },

    capitalWords(value) {
        if (!value) return '';
        return value.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    },
    
    // Date formatter
    date(value, locale) {
        // Create a new Date object from the value
        const date = new Date(value);
        if (locale === "es") {
            // Return the date in the format dd/mm/yyyy
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        } else {
            // Return the date in the format mm-dd-yyyy
            return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        }
    }
    
};

// Helper function to add dots as thousands separators only for CLP
function formatNumberWithDots(number) {
    // Convert the number to a string and add dots as thousands separators
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default filters;
