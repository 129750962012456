export const trackEvent = (event, eventName, properties = {}) => {
    dataLayer.push({
        'event': event,
        'eventName': eventName,
        'properties': properties
    });
    console.log('Event Tracked:');
    console.log({
        'event': event,
        'eventName': eventName,
        'properties': properties
    });
};
