<template>
    <div class="deck row">
        <div v-if="accommodationData.tipo !== 'camper'" class="deck__item col-md mb-2" :class="{ 'opt-active': selected === 1}">
            <div class="col-md-4">
                <img v-if="selected === 1" src="@/assets/alojamiento/casa-white.svg" />
                <img v-else src="@/assets/alojamiento/casa.svg" />
            </div>
            <div class="item-text col-md-8">{{accommodationData.tipo === 'cowork' ? $t('wizard.s4-s7.space') : $t('wizard.s4-s7.house-apartment')}}</div>
        </div>

        <div v-if="showRooms()" class="deck__item col-md mb-2" :class="{ 'opt-active': selected === 2}">
            <div class="col-md-4">
                <img v-if="selected === 2" src="@/assets/alojamiento/room-white.svg" />
                <img v-else src="@/assets/alojamiento/room.svg" />
            </div>
            <div class="item-text col-md-8">{{$t('wizard.s4-s7.room-features')}}</div>
        </div>

        <div v-if="accommodationData.tipo !== 'camper'" class="deck__item col-md mb-2" :class="{ 'opt-active': selected === 3}">
            <div class="col-md-4">
                <img v-if="selected === 3" style="width:30px" src="@/assets/pin.svg" />
                <img v-else style="width:30px" src="@/assets/pin.svg" />
            </div>
            <div class="item-text col-md-8">{{$t('wizard.s4-s7.location-features')}}</div>
        </div>

        <div v-if="accommodationData.tipo !== 'cowork' && accommodationData.tipo !== 'camper'" class="deck__item col-md mb-2" :class="{ 'opt-active': selected === 4}">
            <div class="col-md-4">
                <img v-if="selected === 4" src="@/assets/alojamiento/familia.svg" />
                <img v-else src="@/assets/alojamiento/familia.svg" />
            </div>
            <div class="item-text col-md-8">{{$t('wizard.s4-s7.about-us')}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["selected", "accommodationData"],
    methods: {
        showRooms() {
            const response = Array.isArray(this.accommodationData.rooms)
                ? this.accommodationData.rooms.length > 0
                : this.accommodationData.rooms > 0;
            console.log('showRooms', response);
            return response;
        }
    }
};
</script>

<style scoped lang="scss">
.opt-active {
    background-color: $hf-sec-pine;
    color: #ffffff;
    border-radius: 0;
}

.deck {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        text-align: center;
        border-radius: 4px;
        border: solid 1px rgb(211, 211, 211);
    }

    .item-text {
        padding: 0 10px;
    }

    img {
        width: 50px;
    }
}
</style>