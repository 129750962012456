<template>
	<div class="container contact-container" data-view>
		<div v-if="!sent">
			<h1>{{ $t("contact.contact") }}</h1>
			<div v-html="$t('contact.info')" />
			<form action="?" class="login" @submit.prevent="sendMail()">

                <!-- Name -->
				<div class="contact-label">{{ $t("contact.name") }}:</div>
				<input
					required
					type="text"
					placeholder="John"
					v-model="user.name"
					class="form-control form-control-lg"
				/>

                <!-- Email -->
				<div class="contact-label">{{ $t("contact.email") }}:</div>
				<input
					required
					type="email"
					placeholder="John@gmail.com"
					class="form-control form-control-lg"
					v-model="user.email"
				/>
                
                <!-- Phone -->
				<div class="row">
					<div class="col-12 col-md-6">
						<div class="contact-label">
							{{ $t("contact.phone") }}:
						</div>
						<select
							v-model="user.codigo_pais"
							name="codigo_pais"
							class="form-control custom-select"
							required
						>
                            <optgroup :label="$t('countries-list.highlighted')">
                                <option
                                    v-for="(item, i) in highlightedCountriesTranslated"
                                    :key="`c-${i}`"
                                    :data-country-code="item.code"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </optgroup>
							<optgroup :label="$t('countries-list.other-countries')">
								<option
									v-for="(item, i) in countriesWithCodeTranslated"
									:key="`c-${i}`"
									:data-country-code="item.code"
									:value="item.value"
								>
									{{ item.name }}
								</option>
							</optgroup>
						</select>
					</div>
					<div class="col-12 col-md-6">
						<input
							v-mask="'################'"
							required
							@change="setTelefono($event.target.value)"
							v-model="$v.user.telefono.$model"
							:class="{ 'is-invalid': $v.user.telefono.$error }"
							class="form-control form-control-lg"
							placeholder="9 1234 5678"
						/>
					</div>
				</div>

                <!--  Guest type -->
                <div class="contact-label">{{ $t("contact.user_type.label") }}:</div>
                <select v-model="user.user_type" class="form-control custom-select" required>
                    <option value="host">{{ $t("contact.user_type.host") }}</option>
                    <option value="guest">{{ $t("contact.user_type.guest") }}</option>
                    <option value="other">{{ $t("contact.user_type.other") }}</option>
                </select>

                <!-- Destination/Accommodation country -->
                <div v-if="user.user_type === 'host'" class="contact-label">{{ $t("contact.accommodation-country") }}:</div>
                <div v-else-if="user.user_type === 'guest'" class="contact-label">{{ $t("contact.destination-country") }}:</div>
                <div v-else="user.user_type === 'other'" class="contact-label">{{ $t("contact.interested-in-country") }}:</div>
                
                <select
                    v-model="user.destination_country"
                    name="destination_country"
                    class="form-control custom-select"
                    required
                >
                    <optgroup>
                        <option
                            v-for="(item, i) in countriesTranslated"
                            :key="`c-${i}`"
                            :data-country-code="item.code"
                        >
                            {{ item.name }}
                        </option>
                    </optgroup>
                </select>

                <!-- Message -->
				<div class="contact-label">{{ $t("contact.message") }}:</div>
				<textarea
					class="form-control marg"
					v-model="user.message"
                    style="margin-bottom: 50px"
					required
				></textarea>

                <!-- Captcha -->
                <div
					class="g-recaptcha col-12 col-md-4 offset-md-4"
					data-sitekey="6LdH4vAZAAAAAIiZSVZVJAn9ZyuOwTjklSMRZV5s"
				></div>

                <!-- Send button -->
                <button
					type="submit"
					class="col-12 col-md-4 offset-md-4 btn btn-primary contact-button"
					id="contact-send-button"
				>
					<b-spinner
						v-if="loading"
						label="Spinning"
						style="width: 20px; height: 20px"
					></b-spinner>
					{{ $t("contact.send") }}
				</button>
                
			</form>
		</div>

		<div v-else>
			<div class="col-12 warning-box">{{ $t("contact.sent") }}</div>
		</div>
	</div>
</template>

<script>
import {
	required,
	email,
	sameAs,
	alphaNum,
	minValue,
	minLength,
	maxLength,
	alpha,
	and,
	numeric,
} from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import axios from "axios";
import router from "@/router";
import apiUrls from "@/constants/apiUrls";
import countryMixin from "@/mixins/countryMixin";

export default {
    name: 'Contact',
    mixins: [ countryMixin ],
	data() {
		return {
			user: {
				telefono: "",
				email: "",
				name: "",
				codigo_pais: null,
				message: "",
                destination_country: null,
                user_type: null,
            },
			errors: {
				telefono: false,
			},
			sent: false,
			loading: false,
		};
	},
	directives: { mask },
	methods: {
		setTelefono(value) {
			this.user.telefono = value;
			this.$v.user.telefono.$touch();
		},
		sendMail() {
			if (
				grecaptcha.getResponse() !== "" &&
				grecaptcha.getResponse() !== null
			) {
				this.loading = true;
				this.user.token = grecaptcha.getResponse();
				axios
					.post(
						apiUrls.recaptcha,
						this.user
					)
					.then((response) => {
						if (response.data.success) {
							axios
								.post(
									apiUrls.contact,
									this.user
								)
								.then((value) => {
									this.sent = true;
									this.loading = false;
									setTimeout(function () {
										router.push({ path: "/" });
									}, 5000);
								});
						} else {
							alert(
								"Recaptcha token inválido. Inténtalo de nuevo."
							);
						}
					});
			} else {
				alert("Acepta el recaptcha.");
			}
		},
	},
	validations: {
		user: {
			telefono: {
				numeric,
			},
		},
	},
	created() {
		window.scrollTo(0, 0);
	},
    computed: {
    },
	mounted() {
		let recaptchaScript = document.createElement("script");
		recaptchaScript.setAttribute(
			"src",
			"https://www.google.com/recaptcha/api.js"
		);
		document.head.appendChild(recaptchaScript);
	},
};
</script>

<style lang="scss" scoped>
.contact-container {
	margin-top: 50px;

	.contact-label {
		padding-top: 20px;
	}

	.contact-button {
		margin-top: 20px;
		margin-bottom: 100px;
        padding: 15px 0;
        background-color: $hf-sec-pine;
        border: none;
	}

	textarea {
		min-height: 10em;
	}

	.row {
		display: flex;
		align-items: flex-end;
	}

	.custom-select {
		height: 48px;
	}
}

.warning-box {
	border: 2px solid $hf-sec-pine;
	padding: 15px 25px;
	border-radius: 5px;
	background-color: #f1e9f7;
	color: $hf-sec-pine;
	margin-top: 200px;
	margin-bottom: 400px;
	font-size: 0.9em;
	text-align: center;
}

</style>
