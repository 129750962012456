<template>
	<div>
		<!-- Info Button -->
		<div class="type-info">
			{{ $t("wizard.s1.type") }}
			<button
				class="circular-button"
				v-b-tooltip.hover
				:title="$t('wizard.s1.type-info')"
			>
				<i class="fas fa-info" />
			</button>
		</div>

		<!-- Accommodation Types -->
		<div class="deck deck-container row">
            
            <!-- Complete accommodation -->
			<div
				class="deck__item col-md-4 mb-2"
				:class="{'opt-active': !accommodation_properties.place.entero}"
				@click="set_accommodation_properties_place('entero', false)"
			>
				<div class="col-md-4">
					<img
						v-if="!accommodation_properties.place.entero"
						src="@/assets/alojamiento/casa-white.svg"
					/>
					<img v-else src="@/assets/alojamiento/casa.svg" />
				</div>
				<div class="col-md-8">{{ $t("wizard.s1.entire-house") }}</div>
			</div>

            <!-- Private room -->
			<div
				class="deck__item col-md-4 mb-2"
				:class="{'opt-active': !accommodation_properties.place.privado}"
				@click="set_accommodation_properties_place('privado', false)"
			>
				<div class="col-md-4">
					<img
						v-if="!accommodation_properties.place.privado"
						src="@/assets/alojamiento/room-white.svg"
					/>
					<img v-else src="@/assets/alojamiento/room.svg" />
				</div>

				<div class="col-md-8">{{ $t("wizard.s1.private-room") }}</div>
			</div>

            <!-- Shared room -->
			<div
				class="deck__item col-md-4 mb-2"
				:class="{'opt-active': !accommodation_properties.place.compartido,}"
				@click="set_accommodation_properties_place('compartido', false)"
			>
				<div class="col-md-4">
					<img
						v-if="!accommodation_properties.place.compartido"
						src="@/assets/alojamiento/camas-white.svg"
					/>
					<img v-else src="@/assets/alojamiento/camas.svg" />
				</div>
				<div class="col-md-8">{{ $t("wizard.s1.shared-room") }}</div>
			</div>

		</div>


        <!-- Info Button -->
        <div class="category-info">
            {{ $t("wizard.s1.type") }}
            <button
                class="circular-button"
                v-b-tooltip.hover
                :title="$t('wizard.s1.category-info')"
            >
                <i class="fas fa-info" />
            </button>
        </div>
        
		<!-- CATEGORIES -->
		<div>
			<select
				class="form-control"
				v-model="accommodation.categoria.value"
			>
				<option
					v-for="categoria in categorias"
					:key="`cat-${categoria.id}`"
					:value="categoria.value"
				>
					{{ $t(categoria.translated) }}
				</option>
			</select>
		</div>

        <!-- Accommodation Data -->
        <div class="more-info">
            {{ $t("wizard.s1.more-info") }} <span class="required-message"> ({{ $t("wizard.s1.required-message")}})</span>
        </div>
        
		<div class="form-row">
			<div
				:class="
					accommodation.latitud.error
						? 'form-group col-md-6 error__validation'
						: 'form-group col-md-6'
				"
			>
				<div :class="class_error">
					{{ $t("wizard.s1.exact-address") }}
					<vue-google-autocomplete
						types
						id="to_address_s1"
						class="form-control"
						:class="{ 'is-invalid': accommodation.latitud.error }"
						placeholder="Los leones"
						v-on:placechanged="getToAddress"
						v-on:error="handleError"
						v-model="accommodation.direccion.value"
						@blur="validaGAutoComplete"
					></vue-google-autocomplete>
				</div>

				<div class="text-danger required">
					{{ $t("wizard.s1.field-required") }}
				</div>
			</div>

			<div :class="accommodation.numero.error ? 'form-group col-md-6 error__validation' : 'form-group col-md-6'">

				<span>{{ $t("wizard.s1.property-number") }}</span>

				<input
					type="text"
					class="form-control"
					:class="{ 'is-invalid': accommodation.numero.error }"
					placeholder="2b"
					v-model="accommodation.numero.value"
				/>
				<div class="text-danger required">
					{{ $t("wizard.s1.field-required") }}
				</div>
			</div>

			<div :class="accommodation.m2.error ? 'form-group col-md-6 error__validation' : 'form-group col-md-6'">
				<div>
					<span v-if="accommodation.tipo.value === 'entero'">
                        {{ $t("wizard.s1.surface_entire") }}
                    </span>
					<span v-else>{{ $t("wizard.s1.surface") }}</span>
					<input
						type="number"
						v-mask="'##############'"
						class="form-control"
						:class="{ 'is-invalid': accommodation.m2.error }"
						placeholder="100 m2"
						v-model="accommodation.m2.value"
					/>
				</div>
				<div class="text-danger required">
					{{ $t("wizard.s1.field-required") }}
				</div>
			</div>

			<div :class="accommodation.maximo_huespedes.error ? 'form-group col-md-6 error__validation' : 'form-group col-md-6'">
				<div>{{ $t("wizard.s1.qty-guests") }}</div>

				<input
					class="form-control"
					type="number"
					v-mask="'##############'"
					min="1"
					:class="{ 'is-invalid': accommodation.maximo_huespedes.error }"
					v-model="accommodation.maximo_huespedes.value"
				/>
				<div class="text-danger required">
					{{ $t("wizard.s1.field-required") }}
				</div>
			</div>

			<div :class="accommodation.tiempo_min.error ? 'form-group col-md-6 error__validation' : 'form-group col-md-6'">
				<div>
					{{ $t("wizard.s1.minimum-days") }}
					<input
						type="number"
						v-mask="'##############'"
						class="form-control"
						placeholder="30 dias"
						:class="{ 'is-invalid': accommodation.tiempo_min.error, }"
						:disabled="accommodation.modalidad_tiempo.value === 'mensual'"
						v-model="accommodation.tiempo_min.value"
					/>
				</div>
				<div class="text-danger required">
					{{ $t("wizard.s1.field-required") }}
				</div>
			</div>

            <!-- Rooms -->
			<div
				class="form-group col-md-6"
				v-show="!accommodation_properties.place.entero"
			>
				{{ $t("wizard.s1.property-rooms") }}
                <select
                    v-model="accommodation.rooms.value"
                    class="form-control"
                    name="rooms_number"
                    id="rooms_number"
                    @change="handleRoomSelection"
                >
                    <option :value="0">{{ $t('search.studio') }}</option>
                    <option :key="index" v-for="(n, index) in 10" :value="n">{{ n }}</option>
                </select>
			</div>
		</div>

		<div
			class="mt-4"
			v-show="!accommodation_properties.place.privado || !accommodation_properties.place.compartido"
		>
			<label class="toggle">
				<input
					class="toggle__input"
					type="checkbox"
					@change="privado = !privado"
				/>
				<span class="toggle__label">
					<span class="toggle__text">{{
						$t("wizard.s1.is-residence")
					}}</span>
				</span>
			</label>
		</div>

		<div class="mt-4">
			{{ $t("wizard.s1.rental-mode") }}
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<label class="toggle">
						<input
							class="toggle__input"
							type="radio"
							id="checkbox.diario"
							:checked="checkbox.diario"
							:value="checkbox.diario"
							@click="
								set_accommodation_properties_modality(
									'diario',
									true
								)
							"
							name="mode"
						/>
						<span class="toggle__label">
							<span class="toggle__text">{{
								$t("wizard.s1.daily")
							}}</span>
						</span>
					</label>
				</div>
				<div class="col-sm-12 col-md-4">
					<label class="toggle">
						<input
							class="toggle__input"
							type="radio"
							id="checkbox.mensual"
							:checked="checkbox.mensual"
							:value="checkbox.mensual"
							@click="
								set_accommodation_properties_modality(
									'mensual',
									true
								)
							"
							name="mode"
						/>
						<span class="toggle__label">
							<span class="toggle__text">{{
								$t("wizard.s1.monthly")
							}}</span>
						</span>
					</label>
				</div>

				<div class="col-sm-12 col-md-4">
					<label class="toggle">
						<input
							class="toggle__input"
							type="radio"
							id="checkbox.ambos"
							:checked="checkbox.ambos"
							:value="checkbox.ambos"
							@click="
								set_accommodation_properties_modality(
									'ambos',
									true
								)
							"
							name="mode"
						/>
						<span class="toggle__label">
							<span class="toggle__text">{{
								$t("wizard.s1.both")
							}}</span>
						</span>
					</label>
				</div>
			</div>
		</div>

		<hr />
		<FooterWizard next />
		<AccommodationPopUp />
	</div>
</template>

<script>
// TODO: Refactorizar script
import { mapActions, mapState } from "vuex";
import "@/styles.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import FooterWizard from "./FooterWizard";
import categorias from "../Banner/Category.json";
import AccommodationPopUp from "../PopUps/AccommodationPopUp";

export default {
	name: "Step1Wizard",
	props: {
		data: {
			type: Object,
			default: null,
			required: false,
		},
	},
	data() {
		return {
			categorias: [],
			class_error: "",
			from_address: {},
			to_address: {},
			google_info: {},
			accommodation_properties: {
				place: {
					compartido: true,
					privado: true,
					entero: false,
				},
				modality: {
					diario: true,
					mensual: false,
					ambos: false,
				},
			},
			checkbox: {
				diario: true,
				mensual: false,
				ambos: false,
			},
			accommodation: {
				numero: {
					value: "",
					required: false,
					error: false,
				},
				rooms: {
					value: 0,
					required: false,
					error: false,
				},
				nombre: {
					value: "",
					required: false,
					error: false,
				},
				m2: {
					value: null,
					required: true,
					error: false,
					type: "number",
				},
				maximo_huespedes: {
					value: 1,
					required: true,
					error: false,
					type: "number",
				},
				tiempo_min: {
					value: 1,
					required: true,
					error: false,
					type: "number",
				},
				direccion: {
					value: null,
					required: true,
					error: false,
				},
				latitud: {
					value: null,
					required: true,
					error: false,
				},
				longitud: {
					value: null,
					required: true,
					error: false,
				},
				tipo: {
					value: "entero",
					required: true,
					error: false,
				},
				categoria: {
					value: "casa",
					required: true,
					error: false,
				},
				modalidad_tiempo: {
					value: "diario",
					required: true,
					error: false,
				},
				tipo_moneda: {
					value: "es_CLP",
					required: false,
					error: false,
				}
			}
		};
	},

	components: {
		VueGoogleAutocomplete,
		FooterWizard,
		AccommodationPopUp,
	},

	methods: {
		validaGAutoComplete() {
			if (this.accommodation.direccion === null) {
				this.class_error = "form-group--error";
			}
		},

        handleRoomSelection() {
            this.$emit('set-room', this.accommodation.rooms.value);
        },

		set_accommodation_properties_place(prop, value) {
			Object.keys(this.accommodation_properties.place).forEach((item) => {
				this.accommodation_properties.place[item] = true;
			});
			this.accommodation_properties.place[prop] = value;
			switch (prop) {
				case "entero":
					this.accommodation.tipo.value = "entero";
					this.categorias = [];
					categorias.forEach((item) => {
						if (item.owner.indexOf("entero") !== -1) {
							this.categorias.push(item);
						}
					});
					if (this.$route.name != "editar-alojamiento") {
						this.accommodation.categoria.value = "casa";
					}
					break;
				case "privado":
					this.accommodation.tipo.value = "privado";
					this.categorias = [];
					categorias.forEach((item) => {
						if (item.owner.indexOf("entero") !== -1) {
							this.categorias.push(item);
						}
					});
					if (this.$route.name != "editar-alojamiento") {
						this.accommodation.categoria.value = "casa";
					}
					break;
				case "compartido":
					this.accommodation.tipo.value = "compartido";
					this.categorias = [];
					categorias.forEach((item) => {
						if (item.owner.indexOf("entero") !== -1) {
							this.categorias.push(item);
						}
					});
					if (this.$route.name != "editar-alojamiento") {
						this.accommodation.categoria.value = "casa";
					}
					break;
			}
		},

        // FIXME: the modality value is not being set correctly
		set_accommodation_properties_modality(prop, value) {
			Object.keys(this.accommodation_properties.modality).forEach(
				(item) => {
					this.accommodation_properties.modality[item] = !value;
				}
			);
			this.accommodation_properties.modality[prop] = value;
			switch (prop) {
				case "diario":
					this.accommodation.tiempo_min.value = 1;
					this.accommodation.modalidad_tiempo.value = "diario";
					break;
				case "mensual":
					this.accommodation.tiempo_min.value = 30;
					this.accommodation.modalidad_tiempo.value = "mensual";
					break;
				default:
					this.accommodation.modalidad_tiempo.value = "ambos";
					this.accommodation_properties.modality.diario = value;
					this.accommodation_properties.modality.mensual = value;
					break;
			}
		},

		getToAddress(to_address, placeResultData, id) {
			let locality = "";
			let country = "";
			let admin_area = 0;
			let sub_area = 0;
			if (placeResultData.address_components.length === 1) {
				country = placeResultData.address_components[0].long_name;
				locality = placeResultData.address_components[0].long_name;
			} else {
				placeResultData.address_components.forEach((value) => {
					value.types.forEach((item) => {
						if (item === "country") {
							country = value.long_name;
						}
						if (item === "locality") {
							locality = value.long_name;
						}
						switch (item) {
							case "administrative_area_level_5":
								if (locality.length === 0 && sub_area === 0) {
									locality = value.long_name;
									admin_area = 5;
								}
								break;
							case "administrative_area_level_4":
								if (
									locality.length === 0 &&
									admin_area < 4 &&
									sub_area === 0
								) {
									locality = value.long_name;
									admin_area = 4;
								}
								break;
							case "administrative_area_level_3":
								if (
									locality.length === 0 &&
									admin_area < 3 &&
									sub_area === 0
								) {
									locality = value.long_name;
									admin_area = 3;
								}
								break;
							case "administrative_area_level_2":
								if (
									locality.length === 0 &&
									admin_area < 2 &&
									sub_area === 0
								) {
									locality = value.long_name;
									admin_area = 2;
								}
								break;
							case "administrative_area_level_1":
								if (
									locality.length === 0 &&
									admin_area < 1 &&
									sub_area === 0
								) {
									locality = value.long_name;
									admin_area = 1;
								}
								break;
							case "sublocality_level_5":
								if (locality.length === 0) {
									locality = value.long_name;
									sub_area = 5;
								}
								break;
							case "sublocality_level_4":
								if (locality.length === 0 && sub_area < 4) {
									locality = value.long_name;
									sub_area = 4;
								}
								break;
							case "sublocality_level_3":
								if (locality.length === 0 && sub_area < 3) {
									locality = value.long_name;
									sub_area = 3;
								}
								break;
							case "sublocality_level_2":
								if (locality.length === 0 && sub_area < 2) {
									locality = value.long_name;
									sub_area = 2;
								}
								break;
							case "sublocality_level_1":
								if (locality.length === 0 && sub_area < 1) {
									locality = value.long_name;
									sub_area = 1;
								}
								break;
						}
					});
				});
				if (locality === "") {
					locality = country;
				}
			}
			this.accommodation.latitud.value = to_address.latitude;
			this.accommodation.longitud.value = to_address.longitude;
			this.accommodation.direccion.value =
				placeResultData.formatted_address;
			this.accommodation.nombre.value = locality;
			this.google_info.address_data = to_address;
			this.google_info.google_data = placeResultData;
		},

		validateForm() {
			this.clearError();
			let error = 0;
			Object.keys(this.accommodation).forEach((item) => {
				if (this.accommodation[item].required) {
					if (
						this.accommodation[item].value === null ||
						this.accommodation[item].value === ""
					) {
						this.accommodation[item].error = true;
						error++;
					}
					if (
						this.accommodation[item].type === "number" &&
						this.accommodation[item].value < 1
					) {
						this.accommodation[item].error = true;
						error++;
					}
				}
			});
			window.scrollTo(0, 0);
			return error;
		},

		handleError() {},

		setPlace(value) {
			Object.keys(this.accommodation_properties.place).forEach((item) => {
				this.accommodation_properties.place[item] = true;
			});
			this.set_accommodation_properties_place(value, false);
		},

		setModality(value) {
			switch (value) {
				case "diario":
					this.checkbox.diario = true;
					this.accommodation_properties.modality.diario = true;
					break;
				case "mensual":
					this.checkbox.mensual = true;
					this.accommodation_properties.modality.mensual = true;
					this.accommodation.tiempo_min.value = 30;
					break;
				default:
					this.checkbox.ambos = true;
					Object.keys(this.accommodation_properties.modality).forEach(
						(item) => {
							this.accommodation_properties.modality[item] = true;
						}
					);
					break;
			}
		},

		clearError() {
			Object.keys(this.accommodation).forEach((item) => {
				this.accommodation[item].error = false;
			});
		},

		nextStep() {
			let step = {};
			Object.keys(this.accommodation).forEach((item) => {
				step[item] = this.accommodation[item].value;
			});
			if (this.validateForm() == 0) {
				step = {
					...step,
					...this.accommodation_properties,
					...this.google_info,
				};
                delete step.rooms;
				this.$emit("next", step);
			}
		},
	},

	mounted() {
		window.scrollTo(0, 0);
		this.casa = false;
		if (this.data != null) {
			Object.keys(this.data).forEach((item) => {
				if (item == "nombre") {
					try {
						let name = JSON.parse(this.data[item]);
						this.accommodation[item].value = name.es;
					} catch (err) {
						this.accommodation[item].value = "";
					}
				} else if (item == "rooms") {
                    this.accommodation[item].value = this.data[item].length;
                }
                else {
					this.accommodation[item].value = this.data[item];
				}
			});
			this.checkbox.diario = false;
			this.setModality(this.data.modalidad_tiempo);
			this.setPlace(this.data.tipo);
		} else {
			categorias.forEach((item) => {
				if (item.owner.indexOf("entero") !== -1) {
					this.categorias.push(item);
				}
			});
		}
	},
};
</script>

<style scoped lang="scss">
.deck-container {
	margin: 0;
}

.circular-button {
	background-color: white;
	border-radius: 50%;
	border: solid 1px rgb(196, 196, 196);
	margin-left: 20px;
	width: 40px;
	height: 40px;
	outline: 0;
	margin-top: 0;
	.fa-info {
		color: $hf-sec-pine;
	}

	&:hover {
		margin-top: 0;
		.fa-info {
			color: #fff;
		}
	}
}

.type-info, 
.category-info,
.more-info {
    font-weight: bold;
	margin-top: 30px;
    margin-bottom: 10px;
}

.deck {
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 20px;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
		border: solid 1px rgb(211, 211, 211);
	}

	img {
		width: 50px;
	}
}
.required-message {
	color: #7f7f7f;
	font-size: 0.8em;
}

.form-row {
	margin-top: 20px;
}

.form-control {
	height: 48px;

	&::placeholder {
		color: rgb(221, 221, 221);
	}
}

.opt-active {
	background-color: $hf-sec-pine;
	color: #ffffff;
}

.required {
	display: none;
}

.error__validation .required {
	display: block;
}
</style>