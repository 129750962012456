import SearchQuery from "@/pages/General/SearchQuery.vue";

export default [

    {
        path: '/:lang/accommodation/:search_query',
        name: 'search-query',
        component: SearchQuery
    },

]