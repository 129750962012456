<template>
    <div class="container">
        
        <div class="top-info">{{$t('wizard.s4.features-info')}}:</div>

        <hr />
        <TopS4S7 :selected="1" :accommodationData="accommodationData" />
        <hr />

        <div class="row">
            <div v-for="(item, i) in fields" v-if="!item.studio" :key="`s4-${i}`" class="col-sm-12 col-md-6 col-xl-4 select-item">
                
                <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                <label class="toggleS">
                    <input
                        class="toggleS__input"
                        type="checkbox"
                        v-model="accommodation.caracteristicas[item.field]"
                    />
                    <span class="toggleS__label">
                        <span class="toggleS__text">{{$t(item.text)}}</span>
                    </span>
                </label>
                
            </div>

            <div class="col-12">
                <hr>
            </div>

            <div class="col-md-4" v-for="(item, i) in fields" v-if="item.studio && showStudioField(item)" :key="`s7-${i}`">
                <div class="item">
                    <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                    <div>{{ $t(item.text) }}</div>
                    <button :id="`${item.field}`" type="button" @click="validar">
                        <i class="fas fa-chevron-down" />
                    </button>
                    <input
                        type="number"
                        name="number"
                        min="0"
                        max="100"
                        v-model="accommodation.caracteristicas[item.field]"
                    />
                    <button type="button" @click="accommodation.caracteristicas[item.field]++" :id="`${item.field}-more`">
                        <i class="fas fa-chevron-up" />
                    </button>
                </div>
            </div>

<!--            <div v-for="(item, i) in fields" v-if="item.studio && showStudioField(item)" :key="`s4-${i}`" class="col-sm-12 col-md-6 col-xl-4 select-item">-->

<!--                <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />-->
<!--                <label class="toggleS">-->
<!--                    <input-->
<!--                        class="toggleS__input"-->
<!--                        type="checkbox"-->
<!--                        v-model="accommodation.caracteristicas[item.field]"-->
<!--                    />-->
<!--                    <span class="toggleS__label">-->
<!--                        <span class="toggleS__text">{{ $t(item.text) }}</span>-->
<!--                    </span>-->
<!--                </label>-->

<!--            </div>-->
        </div>
        <FooterWizard next prev />
    </div>
</template>

<script>
import s4Data from "./step4-data.json";
import TopS4S7 from "./TopS4S7";
import FooterWizard from './FooterWizard'

export default {
    name: "Step4Wizard",
    props: {
        data: {
            type: Object,
            default: null
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
    },
    data() {
        return {
            s4Data,
            fields: [],
            accommodation: {
                caracteristicas: {
                    conserje: 0,
                    timbre: 0,
                    bodega: 0,
                    estacionamiento_privado: 0,
                    estacionamiento_visita: 0,
                    estacionamiento_bicicleta: 0,
                    sala_estar: 0,
                    sala_eventos: 0,
                    jardines: 0,
                    piscina: 0,
                    gimnasio_interno: 0,
                    desayuno: 0,
                    refrigerador: 0,
                    cocina: 0,
                    horno: 0,
                    microondas: 0,
                    parrilla: 0,                    
                    lavadora: 0,
                    secadora: 0,
                    plancha: 0,
                    limpieza: 0,
                    mascotas: 0,
                    cama_2_plazas: 0,
                    bano_compartido: 0,
                    escritorio: 0,
                    ac: 0,
                    cama_1_media_plaza: 0,
                    cama_1_plaza: 0,
                    bano_privado: 0,
                    sofa_cama: 0,
                    calefaccion: 0,
                    camarote: 0,
                    velador: 0,
                    wifi: 0,
                    secador: 0,
                    ropa_cama: 0,
                    closet: 0,
                    tv: 0,
                    colgadores: 0,
                    ventilador: 0
                }
            }
        };
    },

    components: {
        TopS4S7,
        FooterWizard
    },
    watch: {
        accommodation: function(val) {
        }
    },
    methods: {
        validar(event) {
            const id = event.target.id;
            if (!this.accommodation.caracteristicas[id] <= 0) {
                this.accommodation.caracteristicas[id]--;
            }
        },
        showStudioField(item) {
            return item.studio === true && this.accommodationData.rooms.length === 0;
        },
        nextStep() {
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.accommodation.caracteristicas[item] ? this.accommodation.caracteristicas[item] : 0;
            });
            this.$emit('next', this.accommodation.caracteristicas);
        },
        prevStep() {
            this.$emit('prev');
        },
        checkType(type) {
            const fields = [];
            s4Data.forEach((item) => {
                const find = item.area.find((i) => i === type)
                if(find) {
                    fields.push(item);
                }
            })
            this.fields = fields;
        },
        addStudioAttributes() {
            s4Data.forEach((item) => {
                if(item.studio && item.studio === true) {
                    this.fields.push(item);
                }
            })
        }
    },
    created() {
        window.scrollTo(0, 0);
        if(this.data != null) {
            Object.keys(this.data).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.data[item];
            })
        }   
        this.checkType(this.accommodationData.tipo)
        this.addStudioAttributes();
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.select-item {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    img {
        margin-right: 10px;
        width: 40px;
    }
}

.item {
    text-align: center;
    margin: 20px 0px;

    img {
        width: 40px;
    }
    button {
        width: 40px;
        border: none;
        background-color: white;
        outline: 0;
        margin: 0;
    }

    input {
        border: none;
        text-align: center;
        padding-left: 12px;
    }

    .fa-chevron-down {
        pointer-events: none;
    }
}

</style>

