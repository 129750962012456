<template>
    <div class="dashboard-sidebar-container">
        
        <div>
            
            <div v-if="isLoggedIn" class="circular-portrait">
                <img v-if="profileImage" :src=profileImage />
                <i v-else class="fa fa-user" />
            </div>

            <div v-else class="mt-5">
                <div class="sign-in-area">
                    <router-link :to="'/login'" @click.native="toggleNav" class="button">
                        {{ $t("main-menu.sign-in") }}
                    </router-link>
                    <router-link :to="{ name: 'register', params: { step: 1 } }" @click.native="toggleNav" class="button">
                        {{ $t("main-menu.sign-up") }}
                    </router-link>
                    <router-link :to="{ name: 'contact' }" @click.native="toggleNav" class="button">
                        {{$t('contact.contact-us')}}
                    </router-link>
                </div>
            </div>

            <!-- TODO -->
            <div v-if="isLoggedIn" class="change-image" @click="openFileBrowser">
                <i class="fa fa-edit" />
                <b-form-file @change="updateProfileImagen" id="uimagen" style="display: none"></b-form-file>
            </div>
            
            <div class="name-profile">{{user.nombre}}</div>
            
            <p class="profile" v-if="user.is_admin == 1">Administrador</p>
            
            <div class="dashboard-sidebar-container__items" :class="!isLoggedIn ? 'blur' : 'clear'">
                <div v-for="(item, i) in menuData" :key="`BMenuDataAdmin-${i}`" @click="closeNav">
                    <router-link :to="item.url" class="dashboard-sidebar-container__item" v-if="item.role === 'admin' && parseInt(user.is_admin) === 1">
                        <img :src="require(`@/assets/${item.image}`)" />
                        {{$t(item.title)}}
                    </router-link>
                </div>
                <div v-for="(item, i) in menuData" :key="`BMenuData-${i}`" @click="closeNav">
                    <router-link :to="item.url" class="dashboard-sidebar-container__item" v-if="item.role === 'public'">
                        <img :src="require(`@/assets/${item.image}`)" />
                        {{$t(item.title)}}
                    </router-link>
                </div>
            </div>

            <a class="dashboard-sidebar-container__item" v-if="isLoggedIn" @click="quitCheck">
                <img src="@/assets/salir.svg" />
                {{$t('dashboard-side-menu.sign-out')}}
            </a>
            
        </div>

        <b-modal id="logout-modal" size="lg" ref="logout-modal" :title="$t('login.title-modal')" hide-footer centered>
            <p class="my-4">{{$t('login.quit-msg')}}</p>
            <hr>
            <div class="row">
                <div class="col-12 text-right">
                    <button class="mx-2 btn btn-outline-primary" @click="closeSection">{{$t('login.btn-ok')}}</button>
                    <button class="mx-2 btn btn-outline-primary" @click="closeModal">{{$t('login.btn-cancel')}}</button>
                </div>
            </div>
        </b-modal>
        
    </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import menuData from "./menu-data.json";
import axios from "axios";
export default {
    name: "DashboardSidebar",
    data() {
        return {
            url1: process.env.VUE_APP_SHARE_URL,
            url: process.env.VUE_APP_S3_URL,
            toggle: true,
            menuData,
            window: {
                width: 0,
                height: 0
            },
        };
    },
    methods: {
        ...mapActions(["logout"]),
        
        openFileBrowser() {
            document.getElementById("uimagen").click();
        },

        quitCheck() {
            this.$refs['logout-modal'].show();
        },
        closeModal() {
            this.$refs['logout-modal'].hide();
        },
        closeSection() {
            this.logout();
            this.$refs['logout-modal'].hide();
        },
        updateProfileImagen(event) {
            let fotodata = new FormData();
            fotodata.append("foto", event.target.files[0]);
            return new Promise(() => {
                axios({
                    url: process.env.VUE_APP_API_URL + "users/updatePhoto",
                    data: fotodata,
                    method: "POST"
                }).then(resp => {
                    this.$store.commit('setUserImage', resp.data.imagen);
                    localStorage.setItem("imagen", resp.data.imagen);
                    // reload the page
                    location.reload();
                });
            });
        },
        toggleNav() {
            this.$parent.toggleNav();
        },
        closeNav() {
            if (this.window.width <= 500) {
                this.$parent.toggleNav();
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    computed: {
        isLoggedIn: function() {
            return this.$store.getters.isLoggedIn;
        },
        ...mapState(["user"]),
        
        profileImage() {
            if (this.user.imagen && this.user.imagen !== "null") {
                return this.url + "perfil/" + this.user.imagen;
            } else {
                return false;
            
            }
        }
    },
    created: function() {
        this.$http.interceptors.response.use(undefined, function(err) {
            return new Promise(function(resolve, reject) {
                if (
                    err.status === 401 &&
                    err.config &&
                    !err.config.__isRetryRequest
                ) {
                    this.$store.dispatch(logout);
                }
                throw err;
            });
        });
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.closeNav();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    }
};
</script>


<style lang="scss" scoped>

.sign-in-area {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .button {
        margin-top: 10px;
        border-radius: $border-radius;
        text-align: center;
        padding: 11px 0px;
        background-color: $hf-sec-pine;
        color: #FFFFFF;
        width: 100%;
    }
}

.btn-outline-primary {
    background-color: #FFF;
    border-color: $hf-sec-pine !important;
    color: $hf-sec-pine;
    &:hover{
        color: #fff;
        background-color: $hf-sec-pine;
        border-color: $hf-sec-pine;
    }
}

.blur {
    filter: blur(2px);
    opacity: 0.5;
}
.clear {
    filter: blur(0px);
}
.dashboard-sidebar-container {
    min-width: 250px;
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 90px;

    .close-button-container {
        text-align: right;
        padding: 10px;
    }

    .change-image {
        text-align: center;
        margin-top: 5px;
        font-size: 1.2rem;
        cursor: pointer;
        .fa-edit {
            color: $hf-sec-pine;
        }
    }

    .circular-portrait {
        overflow: hidden;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        margin: auto;
        margin-top: 15px;
        border: 1px solid $hf-sec-pine;
        img {
            width: 110px;
            height: 110px;
            object-fit: cover;
        }
        .fa-user {
            width: 70px;
            height: 120px;
            margin-left: 20px;
            color: $hf-sec-pine;
        }
    }

    .name-profile {
        text-align: center;
        font-weight: 400;
        color: #525053;
        font-size: 1.2em;
        font-weight: bold;
    }

    .profile {
        text-align: center;
        // font-size: 15px;
        color: #525053;
    }

    .starts-color {
        color: $hf-sec-pine;
        text-align: center;
    }

    .online {
        text-align: center;
        font-weight: 400;
        padding-top: 5px;
        color: #525053;
        .fa-circle {
            color: green;
        }
    }

    &__items {
        margin-top: 15px;
    }

    &__item {
        display: block;
        border-bottom: 0.1px solid #d1d1d1;
        padding: 12px 10px;
        color: #525053;
        font-weight: 400;
        margin: 0px 15px;
        cursor: pointer;

        &:hover {
            color: $hf-sec-pine;
            text-decoration: none;
        }
    }

    img {
        width: 25px;
        margin-right: 10px;
    }

    .copyright {
        padding: 10px;
        //font-weight: 100;
        color: black;
        text-align: center;
    }
}

.c-field__error {
    font-size: 12px;
    color: #d71149;
}

.c-file-input {
    position: relative;
    display: block;
    height: 200px;
    width: 100%;
    border: 2px dashed $hf-sec-pine;
    background-color: #fff;
}

.c-file-input:invalid,
.c-field--error .c-file-input {
    background-color: #ffe6e9;
    border-color: #ff566a;
}

.c-file-input:invalid:focus,
.c-field--error .c-file-input:focus {
    background-color: #ffe6e9;
    border-color: #ff566a;
}

.c-file-input__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 36px;
    padding-right: 36px;
    line-height: 36px;
    color: #999;
    font-size: 12px;
    overflow: hidden;
    word-wrap: break-word;
    z-index: 1;
}

.c-file-input__field {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    z-index: -1;
}

.c-file-input__field:focus {
    outline: none;
}

.c-file-input__indicator {
    position: absolute;
    left: calc(60% - 62px);
    top: 30px;
    height: 100%;
    width: 86px;
    z-index: 0;
}

.c-file-input__indicator__icon {
    color: #bbb;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    left: 4px;
    font-size: 20px;
}

.has-file .c-file-input__indicator__icon {
    color: #d71149;
}

.c-file-input__remove {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 36px;
    z-index: 2;
}

.has-file > .c-file-input__remove {
    display: block;
}

.c-file-input__remove__icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    left: 4px;
    font-size: 20px;
    color: #ff566a;
}

</style>
