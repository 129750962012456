<template>
    <div class="detalle-alojamiento-tabs-container">
        <div class="title">{{$t('accommodation.characteristics')}}</div>
        <div class="detalle-alojamiento-tabs">
            <b-card no-body>
                <b-tabs
                    class="tabs-container"
                    active-nav-item-class="border-light bg-transparent"
                    fill
                    vertical
                >

                    <!-- Caracteristica del local-->
                    <b-tab>

                        <!-- Tab -->
                        <template v-slot:title>
                            <i class="fas fa-home" />
                            <span class="tab-title">{{$t('accommodation.characteristics-house')}}</span>
                        </template>
                        
                        <!-- Tab content -->
                        <b-card-text class="p-2 d-flex flex-wrap">
                            <div v-for="(item, i) in houseData" :key="`H-${i}`" class="p-4 col-6 col-md-4 detail-item" v-if="data[item.field] && showField(item)">
                                <div class="d-flex flex-column align-items-center">
                                    <img :src="require(`@/assets/alojamiento/${item.icon}`)" />
                                    <span class="w-100 text-center">{{data[item.field]}} x {{$t(item.text)}}</span>
                                </div>
                            </div>
                        </b-card-text>
                        
                    </b-tab>

                    <!-- Caracteristica de la ubicacion -->
                    <b-tab>

                        <!-- Tab -->
                        <template v-slot:title>
                            <i class="fas fa-map" />
                            <span class="tab-title">{{$t('accommodation.near-to')}}</span>
                        </template>

                        <!-- Tab content -->
                        <b-card-text class="p-2 d-flex flex-wrap">
                            <div v-for="(item, i) in locationData" :key="`L-${i}`" class="p-4 col-6 col-md-4 detail-item" v-if="data[item.field]">
                                <div class="d-flex flex-column align-items-center">
                                    <img :src="require(`@/assets/alojamiento/${item.icon}`)" v-if="data[item.field]" />
                                    <span class="w-100 text-center">{{data[item.field]}} x {{$t(item.text)}}</span>
                                </div>
                            </div>
                        </b-card-text>
                    </b-tab>

                    <!-- Sobre Nosotros -->
                    <b-tab>

                        <!-- Tab -->
                        <template v-slot:title>
                            <i class="fas fa-user" />
                            <span class="tab-title">{{$t('accommodation.about-us')}}</span>
                        </template>

                        <!-- Tab content -->
                        <b-card-text class="p-2 d-flex flex-wrap">
                            <div class="pt-2 about-us-text" v-if="data.sobre_nosotros">
                                <span v-html="JSON.parse(data.sobre_nosotros)[$i18n.locale]"></span>
                            </div>
                        </b-card-text>

                    </b-tab>

                    <!-- Caracteristica de la habitacion -->
                    <b-tab v-for="(room, index) in data.room" :key="room.idpieza">

                        <!-- Tab -->
                        <template v-slot:title>
                            <i class="fas fa-dot-circle" />
                            <span class="tab-title">{{$t('accommodation.room-features')}} {{ index + 1 }}</span>
                        </template>

                        <!-- Tab content -->
                        <b-card-text class="p-2 d-flex flex-wrap">
                            <div v-for="(item, i) in roomData" :key="`R-${i}`" class="p-4 col-6 col-md-4 detail-item" v-if="room[item.field]">
                                <div class="d-flex flex-column align-items-center">
                                    <img :src="require(`@/assets/alojamiento/${item.icon}`)" />
                                    <span class="w-100 text-center">{{room[item.field]}} x {{$t(item.text)}}</span>
                                </div>
                            </div>
                        </b-card-text>

                    </b-tab>
                    
                </b-tabs>
            </b-card>
        </div>
    </div>
</template>


<script>
import roomData from "./data/room-data.json";
import houseData from "./data/house-data.json";
import locationData from "./data/location-data.json";
import aboutData from "./data/about-data.json";

export default {
    name: "DetalleAlojamientoTabs",
    props: ["data"],
    data() {
        return {
            roomData,
            houseData,
            locationData,
            aboutData
        };
    },
    
    methods: {
        showField(item) {
            return (item.studio === true && this.data.room.length === 0) || !item.studio;
        }
    }
};
</script>

<style scoped lang="scss">

.detalle-alojamiento-tabs-container {
    
    margin-top: 25px;

    .title {
        font-size: 1.2rem;
        //padding: 0 0 0 20px;
    }

    .tabs-container {
        padding: 20px;
    }

    @media (max-width: $md) {
        .card {
            border: none;
        }
    }

    .detalle-alojamiento-tabs {
        margin: 0;
        border-radius: $border-radius;
    }

    .tab-title {
        padding-left: 10px;       
    }

    .detail-item {
        img {
            width: 30px;
        }
    }

    .about-us-text {
        line-height: 3;
    }

    .circular-button {
    background-color: white;
    border-radius: 50%;
    border: solid 1px rgb(196, 196, 196);
    width: 30px;
    height: 30px;
    outline: 0;
    margin-top: 0;
    margin-left: 10px;
    margin-right: 5px;
    .fa-info {
        color: $hf-sec-pine;
    }

    &:hover {
        margin-top: 0;
        background:$hf-sec-pine;;
        .fa-info {
        color: #FFF;
        }
    }
}
    
}
</style>
<style lang="scss">

    .nav-tabs {
        border: none !important;
    }

    .nav-item{
        .nav-link{
            color: $hf-sec-pine;

            &.active {
                color: #333 !important;
                box-shadow: 2px 2px 5px #30164391;
                border-radius: 5px;
            }
        }
    }
</style>