<template>
    <div class="related-search-queries">
        <ul>
            <li v-for="(query, index) in relatedQueries" :key="index" class="query-item">
                <a :href="query.url" target="_self" class="query-link">
                    <img :src="query.image" :alt="query.name" class="query-image" />
                    <span class="query-name">{{ query.name }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "RelatedSearchQuery",
    props: {
        relatedQueries: {
            type: Array,
            required: true
        },
    },
};
</script>

<style lang="scss" scoped>
.related-search-queries {
    ul {
        margin-top: 15px;
        display: flex;
        //flex-wrap: wrap; /* Allow images to wrap if necessary */
        justify-content: center;
        padding: 40px 0;
        gap: 40px;
        list-style: none;
        //height: 250px;

        li {
            height: 200px;
            width: 15%; /* Size of each image in desktop view */
            position: relative;
            border-radius: $border-radius-new;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px); /* Hover animation */
            }

            .query-link {
                text-decoration: none;
                display: flex; /* Use flexbox */
                align-items: center; /* Vertically center content */
                justify-content: center; /* Horizontally center content */
                position: relative;
                height: 100%; /* Ensure the link fills the entire container */
                color: white;
                padding: 0 30px;
            }

            .query-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire space */
                z-index: 1;
                filter: sepia(60%) opacity(100%) saturate(100%) brightness(80%);
                transition: opacity 0.3s ease; /* Add transition for hover effect */
            }

            .query-name {
                position: relative;
                z-index: 2;
                background: rgba(255, 255, 255, 0.8);
                padding: 5px 10px;
                border-radius: 20px;
                font-size: 16px;
                color: #333;
                font-weight: bold;
                text-align: center;
            }
        }
    }

    /* Media Query for mobile view to stack images vertically */
    @media (max-width: $md) {
        ul {
            flex-direction: column !important;
            padding: 0 20px;
            gap: 25px;
        }

        li {
            width: 100% !important; /* Make each image take full width on mobile */
        }
    }
}
</style>
