<template>
	<div>
		
        <div class="banner-container">
			<div class="img">
				<img :src="computedImage" alt="" />
                <h2 v-if="showLocationDescriptionMobile" class="subtitle">{{ computedSubTitle }}</h2>
			</div>

            <!-- Desktop search menu -->
            <div v-if="!isMobile" class="row">
                <div class="col column-left">
                    <div class="banner-container__search">
						<Search />
					</div>
				</div>

				<div class="col column-right">
					<div v-if="computedTitle" class="title">{{ computedTitle }}</div>
					<h2 class="subtitle">{{ computedSubTitle }}</h2>
				</div>
			</div>
		</div>
        
        <!-- Mobile search menu -->
		<div v-if="isMobile" class="mobile-items">
			<Search />
            <hr class="mt-5">
		</div>
        
	</div>
</template>

<script>
import { mapState } from "vuex";
import deviceMixin from "@/mixins/deviceMixin";
import Search from "@/components/Banner/Search";

export default {
	name: "Banner",
	components: {
		Search,
	},
    mixins: [ 
        deviceMixin
    ],
	data() {
		return {
			image: "",
            bg1: "buenos_aires_la_boca.jpg",
            bg2: "buenos_aires_toda_la_ciudad.jpg",
            bg3: "buenos_aires_belgrano.jpg"
		};
	},
    computed: {
        ...mapState('searchQuery', [
            'locationDescription',
            'locationImage'
        ]),
        computedTitle() {
            return this.locationDescription ? '' : this.$t("banner.title");
        },
        computedSubTitle() {
            return this.locationDescription || this.$t("banner.subtitle");
        },
        computedImage() {
            if (this.locationImage) {
                try {
                    return require(`@/assets/locations_images/${this.locationImage}`);
                } catch (error) {
                    console.warn("Error cargando locationImage, usando imagen aleatoria:", error);
                    return this.loadRandomImageFallback();
                }
            } else {
                return this.loadRandomImageFallback();
            }
        },
        showLocationDescriptionMobile() {
            return this.locationDescription && this.isMobile;
        }
        
    },
    methods: {
        getRandomImage() {
            const images = [
                this.bg1,
                this.bg2,
                this.bg3
            ];
            return images[Math.floor(Math.random() * images.length)];
        },
        loadRandomImageFallback() {
            try {
                return require(`@/assets/locations_images/${this.getRandomImage()}`);
            } catch (error) {
                return require('@/assets/locations_images/default.jpg');
            }
        }
    }
};
</script>

<style scoped lang="scss">

.banner-container {

	//background-size: cover;
	//height: 550px;
	//position: relative;

	.img {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}
	}

	@media (max-width: 600px) {
		.img {
			display: flex;
			justify-content: flex-end;
			img {
				width: 1200px;
			}
		}
	}

	&__search {
		background-color: white;
		border-radius: 8px;
		padding: 20px;
		width: 100%;
	}

	.row {
		width: 100%;
		padding: 0;
		margin: 0;
		height: 100%;
	}

	.column-left {
        width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		height: 100%;
        padding: 0 130px;
		@media (max-width: 767px) {
			display: none;
		}
        @media (max-width: 992px) {
			padding: 0 10px;
		}
	}

	.column-right {
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		width: 50%;
		right: 0;
		height: 100%;

		@media (max-width: 540px) {
			width: 100%;
		}
	}

	.title {
        backdrop-filter: brightness(0.7);
		font-weight: 900;
		font-size: 2.5em;
		color: white;
        padding: 10px;
        width: 800px;

        @media (max-width: 500px) {
			font-size: 2em;
		}
	}

	.subtitle {
        backdrop-filter: brightness(0.4);
        padding: 50px;
        line-height: 1.8;
		font-weight: 400;
		color: white;
		font-size: 2em;

        @media (max-width: $md) {
            //position: absolute;
            //width: 100%;
            //text-align: center;
            //height: 100%;
            //display: flex;
            //align-items: center;
            display: none;
            font-size: 1.5em;
        }
	}

	@media (max-width: 767px) {
		height: 500px;
	}
	@media (max-width: 500px) {
		height: 300px;
	}
}

.mobile-items {
    padding: 15px;
    z-index: 1;
}
</style>