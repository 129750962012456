<template>
    <div>
        
        <!-- Footer desktop -->
        <footer class="container-fluid footer desktop-only desktop">
            <div class="menu">
                <div>
                    <router-link :to=appRoutes.privacy>{{$t('footer.privacy')}}</router-link>
                </div>
                <div>
                    <router-link :to=appRoutes.faq>{{$t('footer.faqs')}}</router-link>
                </div>
                <div>
                    <router-link :to=appRoutes.tyc>{{$t('footer.terms-conditions')}}</router-link>
                </div>
                <div>
                    <router-link :to=appRoutes.about>{{$t('footer.about')}}</router-link>
                </div>
                <div>
                    <router-link :to=appRoutes.investment>{{$t('footer.investment')}}</router-link>
                </div>
                
                <div>

                    <!-- Follow us text -->
                    <span>{{$t('footer.follow-us')}}</span>
                    
                    <!-- Facebook -->
                    <a :href=socialMediaLinks.facebook target="_blank" rel=”nofollow”>
                        <i class="fab fa-facebook fa-lg"></i>
                    </a>
                    
                    <!-- Instagram -->
                    <a :href=socialMediaLinks.instagram target="_blank" rel=”nofollow”>
                        <i class="fab fa-instagram fa-lg"></i>
                    </a>
                    
                    <!-- Linkedin -->
                    <a :href=socialMediaLinks.linkedin target="_blank" rel=”nofollow”>
                        <i class="fab fa-linkedin fa-lg"></i>
                    </a>

                    <!-- TikTok -->
                    <a :href=socialMediaLinks.tiktok target="_blank" rel=”nofollow”>
                        <i class="fab fa-tiktok fa-lg"></i>
                    </a>

                    <!-- Whatsapp -->
                    <a :href=socialMediaLinks.whatsapp target="_blank" rel=”nofollow”>
                        <i class="fab fa-whatsapp fa-lg"></i>
                    </a>

                </div>
            </div>
            
        </footer>

        <!-- Footer mobile -->
        <footer class="fixed-bottom footer mobile-only mobile">
            <div class="menu">
                
                <div>
                    <router-link to='/' class="icon">
                        <img src="@/assets/casita.svg" />
                        <div class="label">{{$t('footer.home')}}</div>
                    </router-link>
                </div>

                <div>
                    <router-link to='/favoritos' class="icon">
                        <img src="@/assets/corazon-white.svg" />
                        <div class="label">{{$t('footer.favorite')}}</div>
                    </router-link>
                </div>

                <div>
                    <!-- Logged menu -->
                    <router-link :to=appRoutes.dashboard v-if="isLoggedIn" class="icon">
                        <img src="@/assets/person-white.svg" />
                        <div class="label">{{$t('footer.profile')}}</div>
                    </router-link>

                    <!-- Not logged menu -->
                    <router-link :to=appRoutes.login v-else class="icon">
                        <img src="@/assets/login.svg" />
                        <div class="label">{{$t('footer.login')}}</div>
                    </router-link>
                </div>

                <!-- History back button -->
                <div>
                    <a @click.prevent="goBack" class="icon">
                        <i class="fa fa-chevron-left fa-lg"></i>
                        <div class="label">{{ $t('footer.back') }}</div>
                    </a>
                </div>
                
            </div>
        
        </footer>

        <!-- Copyright -->
        <div class="copyright">{{$t('footer.copyright')}} {{ new Date().getFullYear() }}</div>


    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { socialMediaLinks, appRoutes } from "@/config";

export default {
    name: "Footer",
    data() {
        return {
            socialMediaLinks,
            appRoutes,
            scrollPosition: 0
        }
    },
    computed: {
        ...mapGetters(["isLoggedIn"])
    },
    methods: {
        
        ...mapActions('search', [
            'setShowMapOnMobile'
        ]),
        
        ...mapActions(["logout"]),

        goBack() {
            window.history.go(-1);
            this.setShowMapOnMobile(false)
        }
    },
};
</script>


<style lang="scss" scoped>

.footer {
    
    margin-top: 50px;
    background-color: $hf-primary;
    color: white;
    z-index: 4;
    position: relative;

    .menu {
        display: flex;
        flex-grow: 1;
        justify-content: space-around;
        padding: 7px;
        div {
            display: flex;
            align-items: center;
            text-align: center;
            a {
                margin: 0 10px;
                color: white;
            }
        }
    }
    
    &.mobile {
        height: 60px;
    }
    
    &.desktop {
        height: 48px;
    }
}

.copyright{
    text-align: center;
    color:#000;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
</style>
